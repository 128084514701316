// Global state dispatch reducers

import { Preferences } from '@capacitor/preferences';
import { QuestionData, StateAction, ApplicationState } from './interfaces'
import { uiPopAudio } from './environment';
import { platformActionTypes,
         deviceActionTypes,
         uuidActionTypes,
         soundsOnActionTypes,
         vibrationOnActionTypes,
         submissionsOnActionTypes,
         savedifficultyOnActionTypes,
         loadingScreenActionTypes,
         showProgressActionTypes,
         focusedActionTypes,
         questionsArrActionTypes,
         questionIndexActionTypes,
         startTimeActionTypes,
         scoreSubmittedActionTypes,
         difficultyActionTypes, 
         categoryActionTypes, 
         quizInProgressActionTypes,
         submittedActionTypes,
         correctResponseActionTypes,
         countCorrectActionTypes,
         lastResponseActionTypes,
         lastQTextActionTypes,
         gameUuidActionTypes
        } from './actionTypes';

import { NIL as NIL_UUID } from 'uuid';
import { Howl } from 'howler';

function platformReducer (state: string = '', action: StateAction) {
    switch (action.type) {
        case platformActionTypes.SET_PLATFORM: {
            return action.payload;
        }
        default: 
            return state;
    } 
}

function deviceReducer (state: string = '', action: StateAction) {
    switch (action.type) {
        case deviceActionTypes.SET_DEVICE: {
            return action.payload;
        }
        default: 
            return state;
    } 
}

function uuidReducer (state: string = NIL_UUID, action: StateAction) {
    switch (action.type) {
        case uuidActionTypes.SET_UUID: {
            return action.payload;
        }
        default:
            return state;
    }
}

function gameUuidReducer (state: string = NIL_UUID, action: StateAction) {
    switch (action.type) {
        case gameUuidActionTypes.SET_GAME_UUID: {
            return action.payload;
        }
        default:
            return state;
    }
}


function soundsOnReducer (state: boolean = true, action: StateAction) {
    switch (action.type) {
        case soundsOnActionTypes.SOUNDS_ON: {
            Preferences.set({
                key: 'soundsOn',
                value: '1'
            });
            return true;
        }
        case soundsOnActionTypes.SOUNDS_OFF: {
            Preferences.set({
                key: 'soundsOn',
                value: '0'
            });
            return false;
        }
        default:
            return state;
    }
}

function vibrationOnReducer (state: boolean = true, action: StateAction) {
    switch (action.type) {
        case vibrationOnActionTypes.VIBRATION_ON: {
            Preferences.set({
                key: 'vibrationOn',
                value: '1'
            });
            return true;
        }
        case vibrationOnActionTypes.VIBRATION_OFF: {
            Preferences.set({
                key: 'vibrationOn',
                value: '0'
            });
            return false;
        }
        default:
            return state;
    }
}

function submissionsOnReducer (state: boolean = true, action: StateAction) {
    switch (action.type) {
        case submissionsOnActionTypes.SUBMISSIONS_ON: {
            Preferences.set({
                key: 'submissionsOn',
                value: '1'
            });
            return true;
        }
        case submissionsOnActionTypes.SUBMISSIONS_OFF: {
            Preferences.set({
                key: 'submissionsOn',
                value: '0'
            });
            return false;
        }
        default:
            return state;
    }
}

function savedifficultyOnReducer (state: boolean = true, action: StateAction) {
    switch (action.type) {
        case savedifficultyOnActionTypes.SAVEDIFFICULTY_ON: {
            Preferences.set({
                key: 'savedifficultyOn',
                value: '1'
            });
            return true;
        }
        case savedifficultyOnActionTypes.SAVEDIFFICULTY_OFF: {
            Preferences.set({
                key: 'savedifficultyOn',
                value: '0'
            });
            return false;
        }
        default:
            return state;
    }
}

function loadingScreenReducer (state: boolean = true, action: StateAction) {
    switch (action.type) {
        case loadingScreenActionTypes.SET_LOADING: {
            Preferences.set({
                key: 'loadingScreen',
                value: '1'
            });
            return true;
        }
        case loadingScreenActionTypes.SET_NOT_LOADING: {
            Preferences.set({
                key: 'loadingScreen',
                value: '0'
            });
            return false;
        }
        default:
            return state;
    }
}

function showProgressReducer (state: boolean = true, action: StateAction) {
    switch (action.type) {
        case showProgressActionTypes.SET_SHOW: {
            Preferences.set({
                key: 'showProgress',
                value: '1'
            });
            return true;
        }
        case showProgressActionTypes.SET_NOT_SHOW: {
            Preferences.set({
                key: 'showProgress',
                value: '0'
            });
            return false;
        }
        default:
            return state;
    }
}

function focusedReducer (state: boolean = true, action: StateAction) {
    switch (action.type) {
        case focusedActionTypes.SET_FOCUSED: {
            Preferences.set({
                key: 'focused',
                value: '1'
            });
            return true;
        }
        case focusedActionTypes.SET_NOT_FOCUSED: {
            Preferences.set({
                key: 'focused',
                value: '0'
            });
            return false;
        }
        default:
            return state;
    }
}

function uiAudioReducer (state: Howl = uiPopAudio, action: StateAction) {
    switch (action.type) {
        default: 
            return state;
    }
}

function questionsArrReducer (state: Array<QuestionData> = [], action: StateAction) {
    switch (action.type) {
        case questionsArrActionTypes.SET_QUESTIONS_ARR: {
            return action.payload;
        }
        default: 
            return state;
    }
}

function questionIndexReducer (state: number | undefined = undefined, action: StateAction) {
    switch (action.type) {
        case questionIndexActionTypes.INCREMENT_INDEX: {
            if (state !== undefined) {
                return state + 1;
            } else {
                return 0;
            }
        }
        case questionIndexActionTypes.SET_INDEX: {
            return action.payload;
        }
        default: 
            return state;
    }
}

function startTimeReducer (state: number | undefined = undefined, action: StateAction) {
    switch (action.type) {
        case startTimeActionTypes.SET_TIME: {
            return action.payload;
        }
        default: 
            return state;
    }
}

function scoreSubmittedReducer (state: boolean = false, action: StateAction) {
    switch (action.type) {
        case scoreSubmittedActionTypes.SET_SCORE_SUBMITTED: {
            return action.payload;
        }
        default: 
            return state;
    }
}

function difficultyReducer (state: string = 'easy', action: StateAction) {
    switch (action.type) {
        case difficultyActionTypes.SET_DIFFICULTY: {
            Preferences.set({
                key: 'difficulty',
                value: action.payload
            });
            return action.payload;
        }
        default: 
            return state;
    }
}

function categoryReducer (state: string = 'random', action: StateAction) {
    switch (action.type) {
        case categoryActionTypes.SET_CATEGORY: {
            return action.payload;
        }
        default: 
            return state;
    }
}

function quizInProgressReducer (state: boolean = false, action: StateAction) {
    switch (action.type) {
        case quizInProgressActionTypes.SET_IN_PROGRESS: {
            return true;
        }
        case quizInProgressActionTypes.SET_NOT_IN_PROGRESS: {
            return false;
        }
        default: 
            return state;
    }
}

function submittedReducer (state: boolean = false, action: StateAction) {
    switch (action.type) {
        case submittedActionTypes.SET_SUBMITTED: {
            return true;
        }
        case submittedActionTypes.SET_NOT_SUBMITTED: {
            return false;
        }
        default: 
            return state;
    }
}

function correctResponseReducer (state: boolean = false, action: StateAction) {
    switch (action.type) {
        case correctResponseActionTypes.SET_CORRECT: {
            return true;
        }
        case correctResponseActionTypes.SET_INCORRECT: {
            return false;
        }
        default: 
            return state;
    }
}

function lastResponseReducer (state: string = '', action: StateAction) {
    switch (action.type) {
        case lastResponseActionTypes.SET_RESPONSE: {
            return action.payload;
        }
        default:
            return state;
    }
}

function lastQTextReducer (state: string = '', action: StateAction) {
    switch (action.type) {
        case lastQTextActionTypes.SET_TEXT: {
            return action.payload;
        }
        default:
            return state;
    }
}

function countCorrectReducer (state: number = 0, action: StateAction) {
    switch (action.type) {
        case countCorrectActionTypes.INCREMENT_COUNT: {
            return state + 1;
        }
        case countCorrectActionTypes.SET_COUNT: {
            return action.payload;
        }
        default: 
            return state;
    }
}

export default function rootReducer(state: ApplicationState, action: StateAction): ApplicationState {
    const { 
            platform,
            device,
            uuid,
            soundsOn,
            vibrationOn,
            submissionsOn,
            savedifficultyOn,
            loadingScreen,
            showProgress,
            focused,
            uiAudio,
            currentQuestionsArray, 
            currentQuestionIndex,
            startTime,
            scoreSubmitted,
            currentDifficulty, 
            currentCategory, 
            quizInProgress,
            submitted,
            correctResponse,
            lastResponse,
            lastQText,
            countCorrect,
            gameUuid
        } = state;

    return {
        platform:               platformReducer(platform, action),
        device:                 deviceReducer(device, action),
        uuid:                   uuidReducer(uuid, action),
        soundsOn:               soundsOnReducer(soundsOn, action),
        vibrationOn:            vibrationOnReducer(vibrationOn, action),
        submissionsOn:          submissionsOnReducer(submissionsOn, action),
        savedifficultyOn:       savedifficultyOnReducer(savedifficultyOn, action),
        loadingScreen:          loadingScreenReducer(loadingScreen, action),
        showProgress:           showProgressReducer(showProgress, action),
        focused:                focusedReducer(focused, action),
        uiAudio:                uiAudioReducer(uiAudio, action),
        currentQuestionsArray:  questionsArrReducer(currentQuestionsArray, action),
        currentQuestionIndex:   questionIndexReducer(currentQuestionIndex, action),
        startTime:              startTimeReducer(startTime, action),
        scoreSubmitted:         scoreSubmittedReducer(scoreSubmitted, action),
        currentDifficulty:      difficultyReducer(currentDifficulty, action),
        currentCategory:        categoryReducer(currentCategory, action),
        quizInProgress:         quizInProgressReducer(quizInProgress, action),
        submitted:              submittedReducer(submitted, action),
        correctResponse:        correctResponseReducer(correctResponse, action),
        lastResponse:           lastResponseReducer(lastResponse, action),
        lastQText:              lastQTextReducer(lastQText, action),
        countCorrect:           countCorrectReducer(countCorrect, action),
        gameUuid:               gameUuidReducer(gameUuid, action),
    }
}
/**
 * Functions for AdMob implementation
 */

import { AdMob, BannerAdOptions, BannerAdSize, BannerAdPosition } from '@capacitor-community/admob';
import { adMobIds, ENVIRONMENT } from './environment';

var adMobFuncs = {
    showBanner,
    hideBanner,
    resumeBanner,
    removeBanner
}

export default adMobFuncs;

function showBanner(adId: string) {
    const options: BannerAdOptions = {
        // Replace with adId for actual banner ids
        //adId: adMobIds.testBanner,
        adId: ENVIRONMENT === 'production' ? adId : adMobIds.testBanner,
        adSize: BannerAdSize.ADAPTIVE_BANNER,
        position: BannerAdPosition.TOP_CENTER
    }

    AdMob.showBanner(options)
        .then((value: any) => {
            //console.log(value);
        },
        (error: any) => {
            console.error(error); // show error
        });
}

function hideBanner() {
    AdMob.hideBanner().then(
        async (value: any) => {
            //console.log(value);  // true
        },
        (error: any) => {
            console.error(error); // show error
        });
}

function resumeBanner() {
    // Resume the banner, show it after hide
    AdMob.resumeBanner().then(
        (value: any) => {
            //console.log(value);  // true
        },
        (error: any) => {
            console.error(error); // show error
        });
}

function removeBanner() {
    // Destroy the banner, remove it from screen.
    AdMob.removeBanner().then(
        (value: any) => {
            //console.log(value);  // true
        },
        (error: any) => {
            console.error(error); // show error
        });
}


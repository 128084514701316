// Global State framework from https://www.g33kchris.net/blog/using-react-context-to-manage-state

import React, { createContext, useReducer, useCallback } from 'react';
import { StateProviderProps, IStateContext, ApplicationState } from './ts/interfaces';
import rootReducer from './ts/reducers';

import { uiPopAudio } from './ts/environment';
import { NIL as NIL_UUID } from 'uuid';

// A basic empty context object.
export const GlobalStore = createContext({} as IStateContext);

// Initial Application State
const InitialState: ApplicationState = {
    platform:              '',
    device:                '',
    uuid:                  NIL_UUID,
    soundsOn:              true,
    vibrationOn:           true,
    submissionsOn:         true,
    savedifficultyOn:      true,
    loadingScreen:         false,
    showProgress:            true,
    uiAudio:               uiPopAudio,
    focused:               false,
    currentQuestionsArray: [],
    currentQuestionIndex:  undefined,
    startTime:             0,
    scoreSubmitted:        false,
    currentDifficulty:     'easy',
    currentCategory:       'random',
    quizInProgress:        false,
    submitted:             false,
    correctResponse:       false,
    lastResponse:          '',
    lastQText:             '',
    countCorrect:          0,
    gameUuid:              '',
} 

// An wrapping function to handle thunks (dispatched actions which are wrapped in a function, needed for async callbacks)
const asyncer = (dispatch: any, state: ApplicationState) => (action: any) =>
    typeof action === 'function' ? action(dispatch, state) : dispatch(action);

// The StateProvider component to provide the global state to all child components
export function StateProvider(props: StateProviderProps) {
    const [state, dispatchBase] = useReducer(rootReducer, InitialState);

    // eslint-disable-next-line
    const dispatch = useCallback(asyncer(dispatchBase, state), [])

    return (
        <GlobalStore.Provider value={{ state, dispatch }}>
            { props.children }
        </GlobalStore.Provider>
    )
}

// A higher order component to inject the state and dispatcher
export default function withApplicationState(Component: any) {
    return function WrapperComponent(props: any) {
        return (
            <GlobalStore.Consumer>
                {context => <Component {...props} store={context.state} dispatch={context.dispatch} />}
            </GlobalStore.Consumer>
        );
    }
}
/** Question Screen Component */

import React from 'react';
import { IonButton, IonIcon, IonLabel, IonSegment, IonSegmentButton, IonReorderGroup, IonReorder, IonItem, IonImg } from '@ionic/react';
import { ItemReorderEventDetail } from '@ionic/core';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import * as curlyquotes from 'curlyquotes';
import '../theme/Question.css';

import withApplicationState from '../store';
import AdMobFuncs from '../ts/adMob';
import { QuestionData, ApplicationState } from '../ts/interfaces';
import { ENVIRONMENT } from '../ts/environment';
//import { Preferences } from '@capacitor/preferences';
import { Howl } from 'howler';

import reorderIcon from '../assets/icons/ui/reorder.svg';
import { correctResponseActionTypes, countCorrectActionTypes, lastQTextActionTypes, lastResponseActionTypes, submittedActionTypes } from '../ts/actionTypes';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
//import { convertCompilerOptionsFromJson } from 'typescript';

/**
 * Helper function to process formatting for language names
 */
function formatLangName (str: string) {
  // Remove Hyphens
  str = str.replace('-', ' ');

  // Capitalize all first letters
  let words = str.split(' ');
  words.forEach((word, i) => {
    if ((word !== "language") && (word !== "the")) {
      words[i] = word[0].toUpperCase() + word.slice(1);
    }
  });

  return words.join(' ');
}

interface QuestionProps {
  store:             ApplicationState;
  qReview:            boolean;
  dispatch:          ({ type }: { type: string; payload?: any; }) => void;
};

interface QuestionState {
  selectedAnswer:   string       | undefined;
  currentOrder:     Array<string | undefined>;
}

class Question extends React.Component<QuestionProps, QuestionState> {
  private _questionData: QuestionData;
  private _timeInitial: number;

  constructor(props: QuestionProps) {
    super(props);

    const { currentQuestionsArray, currentQuestionIndex } = this.props.store;
    this._questionData = currentQuestionsArray[currentQuestionIndex!];
    this._timeInitial = + new Date().getTime();

    this.state = {
      selectedAnswer:   undefined,
      currentOrder:     currentQuestionsArray[currentQuestionIndex!].options,
    };
  }

  
  componentDidMount() {
    const { platform } = this.props.store;
    let { options } = this._questionData;
    this.setState({currentOrder: options});

    // Store question text in global state so we can send it with analytics event
    this.props.dispatch({
      type: lastQTextActionTypes.SET_TEXT,
      payload: this.getQuestionText()
    });
  }

  /**
   *  Returns question prompt text according to question type
   */
  private getQuestionText () {
      const { type, category, subject, subjectLangs, range, article, subcat } = this._questionData;
      const { currentDifficulty } = this.props.store;

      if (type === "category-include") {
        // Text for category-include questions
        switch (category) {
          case "brands" :
            return "Choose the word that HAS been used as a brand name:";
          case "sports" :
            return "Choose the word that HAS been used as sports terminology:";
          case "money" :
            return "Choose the word that HAS been used as financial terminology:";
          case "arts" :
            return "Choose the word that HAS been used as terminology within the arts:";
          case "imitative" :
            return "Choose the word that HAS been used as an imitation of a sound:";
          case "food" :
            return "Choose the word that HAS been used in relation to food or drink:";
          case "imported" :
              return "Choose the word that WAS imported into English:";
          default : 
            return "Invalid Category";
        }
      } else if (type === "category-exclude") {
        // Text for category-exclude questions
        switch (category) {
          case "brands" :
            return "Choose the word that has NOT been used as a brand name:";
          case "sports" :
            return "Choose the word that did NOT originate as sports terminology:";
          case "money" :
            return "Choose the word that did NOT originate as financial terminology:";
          case "arts" :
            return "Choose the word that did NOT originate as terminology within the arts:";
          case "imitative" :
            return "Choose the word that did NOT originate as an imitation of a sound:";
          case "food" :
            return "Choose the word that has NOT been used in relation to food or drink:";
          case "imported" :
              return "Choose the word that was NOT imported into English:";
          default :
            return "Invalid Category";
        }
      } else if (type === "origin-include" && subjectLangs) {
        // Text for origin-include questions
        subjectLangs.forEach((lang, i) => subjectLangs[i] = formatLangName(lang));
        switch (subjectLangs.length) {
          case 1: 
            return "Which word comes from " + subjectLangs[0] + "?";
          case 2:
            return "Which word comes from " + subjectLangs[0] + " and " + subjectLangs[1] + "?";
          default:
            return "Which word comes from languages including " + subjectLangs.slice(0, subjectLangs.length - 1).join(', ') + ", and " + subjectLangs[subjectLangs.length - 1] + "?";
        }
      } else if (type === "origin-exclude" && subjectLangs) {
        // Text for origin-exclude questions
        subjectLangs.forEach((lang, i) => subjectLangs[i] = formatLangName(lang));
        switch (subjectLangs.length) {
          case 1: 
            return "Which word does NOT come from " + subjectLangs[0] + "?";
          case 2:
            return "Which word does NOT come from " + subjectLangs[0] + " and " + subjectLangs[1] + "?";
          default:
            return "Which word does NOT come from languages including " + subjectLangs.slice(0, subjectLangs.length - 1).join(', ') + ", and " + subjectLangs[subjectLangs.length - 1] + "?";
        }
      } else if (type === "origin-multi") {
        // Text for origin-multi questions
        switch (currentDifficulty) {
          case "moderate":
            return "Which two languages does \"" + subject + "\" come from?";
          case "hard":
            return "From which of these languages does modern English get the word \"" + subject + "\"?";
          default:
            return "Which language does \"" + subject + "\" come from?";
        }
      } else if (type === "subcat-include") {
        // Text for subcat-include questions
        if (subcat === "apparel") {
          return "Which of these words HAS been used in relation to apparel?";
        } else if (subcat === "appearance") {
          return "Which of these words HAS been used in relation to appearance?";
        } else if (subcat === "currency") {
          return "Which of these words HAS been used in relation to currency?";
        } else if (subcat === "music") {
          return "Which of these words HAS been used in music vernacular?";
        } else if (subcat === "dance") {
          return "Which of these words HAS been used in dance?";
        } else if (subcat === "person") {
          return "Which of these words HAS been used in relation to a historical figure's name?";
        } else if (subcat === "place") {
          return "Which of these words HAS been used in relation to a place name?";
        } else if (article) {
          return "Which of these words HAS been used in relation to " + article.slice(1, -1) + " " +  subcat + "?";
        } else {
          return "Which of these words HAS been used in " +  subcat + "?";
        }
      } else if (type === "subcat-exclude") {
        // Text for subcat-include questions
        if (subcat === "apparel") {
          return "Which of these words has NOT been used in relation to apparel?";
        } else if (subcat === "appearance") {
          return "Which of these words has NOT been used in relation to appearance?";
        } else if (subcat === "currency") {
          return "Which of these words has NOT been used in relation to currency?";
        } else if (subcat === "music") {
          return "Which of these words has NOT been used in music vernacular?";
        } else if (subcat === "dance") {
          return "Which of these words has NOT been used in dance?";
        } else if (subcat === "person") {
          return "Which of these words has NOT been used in relation to a historical figure's name?";
        } else if (subcat === "place") {
          return "Which of these words has NOT been used in relation to a place name?";
        } else if (article) {
          return "Which of these words has NOT been used in relation to " + article.slice(1, -1) + " " +  subcat + "?";
        } else {
          return "Which of these words has NOT been used in " +  subcat + "?";
        }
      } else {
        // Prefix "the" when options will be decades or centuries e.g. "the 1910s" or "the 19th century"
        let rangePrefix = '';

        if (range?.endsWith('century')) {
          rangePrefix = 'the ';
        }

        // All other question types
        switch (type) {
          case "date" :
            return "When is the word \"" + subject + "\" first recorded in English?";
          case "date-range-include" :
            return "Which of the following words IS first recorded in " + rangePrefix + range + "?";
          case "date-range-exclude" :
            return "Which of the following words is NOT first recorded in " + rangePrefix + range + "?";
          case "order" :
            return "Order the following words descending by date of first use (First used word on top):";
          case "first" :
            return "Which of the following words WAS the first to appear in English?";
          case "last" :
            return "Which of the following words WAS the last to appear in English?";
          case "subcat-multi" :
            return "The word " + subject + " comes from: ";
        default:
          return "Invalid question type: " + type
      }
    }
  }

  /**
   * Change state when user selects an answer
   * @param val value of selected answer
   */
  setSelectedAnswer(val: string | undefined) {
    const { platform, soundsOn, uiAudio, vibrationOn, focused } = this.props.store;

    this.setState({ selectedAnswer: val });
    if (focused && soundsOn) {
      const uiPopAudio: Howl = new Howl({
        src: ['assets/pop.mp3'],
        format: ['mp3'],
        volume: 0.3,
        onloaderror: (id, e) => console.log('failed: ' + id + ' msg: ' + e)
    });
    uiPopAudio.play();
    } else {
      if (soundsOn) uiAudio.play();
    }
    if (platform !== 'web' && vibrationOn) Haptics.impact({ style: ImpactStyle.Light });
  }

  /**
   * Required procedure to change state on reorder event
   * @param event reorder event
   */
  async doReorder(event: CustomEvent<ItemReorderEventDetail>) {
    let { currentOrder } = this.state;

    let from = event.detail.from;
    let to = event.detail.to;
    let newOrder = [...currentOrder];

    // Remove element at index and splice it into the new index
    newOrder.splice(from, 1)
    newOrder.splice(to, 0, currentOrder[from]);

    this.setState({currentOrder: newOrder});
    event.detail.complete();
  }

  /**
   * Procedure to check if submitted answer is correct upon submission
   * @param ans submitted answer
   */
  answerCheck(ans: string | Array<string | undefined>) {
    const { currentQuestionsArray, currentQuestionIndex, platform, soundsOn, vibrationOn, uiAudio, focused } = this.props.store;
    const { type, answer, subject } = this._questionData;

    // Set result as correct answer, try to invalidate
    let correct = true;

    if (focused && soundsOn) {
      const uiPopAudio: Howl = new Howl({
        src: ['assets/pop.mp3'],
        format: ['mp3'],
        volume: 0.3,
        onloaderror: (id, e) => console.log('failed: ' + id + ' msg: ' + e)
    });
    uiPopAudio.play();
    } else {
      if (soundsOn) uiAudio.play();
    }
    
    if (platform !== 'web' && vibrationOn) Haptics.impact({style: ImpactStyle.Light});

    if (currentQuestionsArray.length > 0 && 
        currentQuestionIndex  !== undefined) {

      if (type === 'order') {
          // If array, then cast var and check with loop
          let ansOrder = ans as Array<string>;
          ansOrder.forEach((element, i) => {
            if (answer[i] !== element) {
              correct = false;
            }
          });
      } else if (ans !== answer) {
        correct = false;
      }

      //console.log('GAME UUID IN QUESTION ANSWERED');
      //console.log(this.props.store.gameUuid);

      // If answer is a specific word, log event
      if (ENVIRONMENT === 'production') {
        if (subject) {
          let timeFinal = + new Date().getTime();
          FirebaseAnalytics.logEvent({
            name: 'question_answered',
            params: {
              game_uuid: this.props.store.gameUuid,
              subject_word: subject,
              subject_word_diff: this._questionData.subjectDiff,
              question_text: this.getQuestionText(),
              question_type: this._questionData.type,
              question_time_msec: timeFinal - this._timeInitial,
              answered_correctly: correct,
              correct_answer: answer
            }
          });
        }
      }

      //console.log(answer);
      
      this.props.dispatch({
        type: lastResponseActionTypes.SET_RESPONSE,
        payload: ans
      });

      this.props.dispatch({
        type: submittedActionTypes.SET_SUBMITTED
      });

      if (correct) {
        this.props.dispatch({
          type: correctResponseActionTypes.SET_CORRECT
        });

        this.props.dispatch({
          type: countCorrectActionTypes.INCREMENT_COUNT
        })
      } else {
        this.props.dispatch({
          type: correctResponseActionTypes.SET_INCORRECT
        });
      }
    }
  }
  
  render() {
    const { type,reviewImgURL, answer, optionsDates } = this._questionData;
    var { options } = this._questionData;
    const { selectedAnswer, currentOrder } = this.state;
    const { qReview } = this.props;
    const { currentDifficulty } = this.props.store;

    // Use current order state as answer when question is an order question
    // Otherwise disable submit button until an answer is selected
    let submitButton = <IonButton disabled>SUBMIT</IonButton>
    if (type === 'order') {
      submitButton = <IonButton disabled={false} onClick={() => this.answerCheck(currentOrder)}>SUBMIT</IonButton>
    } else if (selectedAnswer) {
      submitButton = <IonButton disabled={false} onClick={() => this.answerCheck(selectedAnswer)}>SUBMIT</IonButton>
    }

    if (qReview) {
      submitButton = <></>
    }

    // Sort date options
    if (type === 'date') {
      options.sort();
    }

    var qWrapperClass = "question-wrapper " + currentDifficulty;

    var questionText = curlyquotes.string(this.getQuestionText());
    var questionTextLength = questionText.length;
    var questionTextClass = ''
    if (questionTextLength <= 60) questionTextClass = 'question-header';
    if (questionTextLength > 60) questionTextClass = 'question-header med';
    if (questionTextLength > 70) questionTextClass = 'question-header long';
    if (questionTextLength > 80) questionTextClass = 'question-header extra-long';

    return (
      <div className={qWrapperClass}>
        
        <div className={questionTextClass}>
          <p>
            { questionText }
          </p>
        </div>
        { (type === 'order') ? 
            typeof answer === "object" && qReview && optionsDates? 
            // REORDER QUESTIONS QREVIEW
            <IonSegment mode="md" value={selectedAnswer} onIonChange={e => this.setSelectedAnswer(String(e.detail.value))}>
                {answer.map((option, i) => {
                    return(
                    <IonSegmentButton disabled className='qreview-button' value={option} key={i}>
                      <IonLabel>{option?.toUpperCase() + ":  " + optionsDates[option]}</IonLabel>
                    </IonSegmentButton>
                );
              })
            }
          </IonSegment>
            :         
            // REORDER QUESTIONS
            <IonReorderGroup disabled={false} onIonItemReorder={e => this.doReorder(e)}>
  
              {options.map((option, i) => {
                  return(
                    <IonReorder slot='start' key={i}>
                      <IonItem className='ion-text-center' lines='none'>
                        <div className='item-wrapper'>
                          <IonIcon icon={reorderIcon} />
                          <IonLabel mode="md" position='stacked'>{option?.toUpperCase()}</IonLabel>
                        </div>
                      </IonItem>
                    </IonReorder>
                  );
                })
              }
            </IonReorderGroup>
            : // SINGLE-SELECT QUESTIONS
            <IonSegment mode="md" value={selectedAnswer} onIonChange={e => this.setSelectedAnswer(String(e.detail.value))}>
              { options.map((option, i) => {
                  let optionTextLength = 'short';
                  let buttonClass = type === 'date' ? 'date-button-text' : '';
                  if (qReview) {
                    buttonClass += ' qreview-button';
                    if ( option === answer) {
                      buttonClass += ' segment-button-checked';
                    }
                  }
                  if (option && option.length > 20) optionTextLength = 'med';
                  if (option && option.length > 27) optionTextLength = 'long';
                  if (option && option.length > 33) optionTextLength = 'extra-long';
                  let optionsButtons = 
                  qReview ?
                    <IonSegmentButton disabled className={buttonClass} value={option} key={i}>
                      <IonLabel className={optionTextLength}>{option}</IonLabel>
                    </IonSegmentButton>
                    :
                    <IonSegmentButton className={buttonClass} value={option} key={i}>
                      <IonLabel className={optionTextLength}>{option}</IonLabel>
                    </IonSegmentButton>
                  return(
                    optionsButtons
                  );
                })
              }
            </IonSegment>
        }
        <IonImg style={{visibility: 'hidden', height: '0'}} src={reviewImgURL}/>
        {submitButton}
      </div>
    )
  }
};

export default withApplicationState(Question);
import React from 'react';
import '../theme/SetupUI.css';
import { IonButton, IonIcon, IonLabel, IonSegment, IonSegmentButton, IonModal, IonAlert } from '@ionic/react';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { Howl } from 'howler';

import withApplicationState from '../store';
import { ApplicationState, QuestionData } from '../ts/interfaces';
import { Preferences } from '@capacitor/preferences';
import { categoryActionTypes, countCorrectActionTypes, difficultyActionTypes, questionsArrActionTypes, quizInProgressActionTypes, questionIndexActionTypes, startTimeActionTypes, scoreSubmittedActionTypes  } from '../ts/actionTypes';

import iconRandom from '../assets/icons/random/icons_wordigins_random_white.svg';
import iconBrands from  '../assets/icons/brand/icons_wordigins_brand_white.svg';
import iconSports from  '../assets/icons/sports/icons_wordigins_sports_white.svg';
import iconArts   from  '../assets/icons/arts/icons_wordigins_arts_white.svg';
import iconMoney  from '../assets/icons/money/icons_wordigins_money_white.svg';
import iconImitative from '../assets/icons/imitative/icons_wordigins_imitative_white.svg';
import iconFood from '../assets/icons/food/icons_wordigins_food_white.svg';
import iconImported from '../assets/icons/imported/icons_wordigins_import_white.svg';

// Future categories?
// import iconReligious from '../assets/icons/religion/icons_wordigins_religion_white.svg';


interface SetupUIProps { 
  store:             ApplicationState;
  dispatch:          ({ type }: { type: string; payload?: any; }) => void;

  callback:   () => void ;
};

interface SetupUIState {
  wordDifficulty: string;
  loadModalOpen:   boolean;
}

class SetupUI extends React.Component<SetupUIProps, SetupUIState> {
  constructor(props: SetupUIProps) {
    super(props);

    this.state = {
      wordDifficulty: 'none',
      loadModalOpen:   false,
    };
  }

  async componentDidMount () {
    this.props.dispatch({
      type: scoreSubmittedActionTypes.SET_SCORE_SUBMITTED,
      payload: true
    });
  }

  timeout(delay: number) {
    return new Promise( res => setTimeout(res, delay) );
  }

  openLoadModal () {
    this.setState({
      loadModalOpen:   true,
    });
  }

  closeLoadModal () {
    this.setState({
      loadModalOpen:   false,
    });
  }
  
  setWordDiff (wDiff: string) {
    this.setState({
      wordDifficulty: wDiff
    });
  }

  setQuestionDiff (qDiff: string) {
    const { platform, soundsOn, vibrationOn, uiAudio, focused } = this.props.store;

    this.props.dispatch({
      type: difficultyActionTypes.SET_DIFFICULTY,
      payload: qDiff
    });

    if (focused && soundsOn) {
      const uiPopAudio: Howl = new Howl({
        src: ['assets/pop.mp3'],
        format: ['mp3'],
        volume: 0.3,
        onloaderror: (id, e) => console.log('failed: ' + id + ' msg: ' + e)
    });
    uiPopAudio.play();
    } else {
      if (soundsOn) uiAudio.play();
    }

    if (platform !== 'web' && vibrationOn) Haptics.impact({style: ImpactStyle.Light});
  }

  setCategory(cat: string) {
    const { platform, soundsOn, vibrationOn, uiAudio, focused, currentCategory  } = this.props.store;

    if ( cat !== "undefined" ) {
      this.props.dispatch({
        type: categoryActionTypes.SET_CATEGORY,
        payload: cat
      });

      if ( cat !== currentCategory ) {
        if (focused && soundsOn) {
          const uiPopAudio: Howl = new Howl({
            src: ['assets/pop.mp3'],
            format: ['mp3'],
            volume: 0.3,
            onloaderror: (id, e) => console.log('failed: ' + id + ' msg: ' + e)
        });
        uiPopAudio.play();
        } else {
          if (soundsOn) uiAudio.play();
        }
      }
      
      if (platform !== 'web' && vibrationOn) Haptics.impact({style: ImpactStyle.Light});
    } else {
      this.props.dispatch({
        type: categoryActionTypes.SET_CATEGORY,
        payload: currentCategory
      });
    }
  }

  async checkSavedGame(){
    if ((await Preferences.get({key: 'savedGame'})).value === '1') {
      this.openLoadModal();
    } else {
      this.startNewGame();
    }
    
  }

  async loadSavedGame() {
    //close load modal
    this.closeLoadModal();
    //load game data from storage
    const countCorrectRecord = await Preferences.get({key: 'countCorrectKey'});
    const currentQuestionIndexRecord = await Preferences.get({key: 'currentQuestionIndexKey'});
    const currentCategoryRecord = await Preferences.get({key: 'currentCategoryKey'});
    const currentDifficultyRecord = await Preferences.get({key: 'currentDifficultyKey'});
    const scoreSubmittedRecord = await Preferences.get({key: 'scoreSubmittedKey'});
    const currentQuestionsArrayRecord = await Preferences.get({key: 'currentQuestionsArrayKey'});
    const currentTime = await Preferences.get({key: 'currentTime'})
    const countCorrectValue = countCorrectRecord.value;
    const currentQuestionIndexValue = currentQuestionIndexRecord.value;
    const currentCategoryValue = currentCategoryRecord.value;
    const currentDifficultyValue = currentDifficultyRecord.value;
    const scoreSubmittedValue = scoreSubmittedRecord.value;
    const currentQuestionsArrayValue = currentQuestionsArrayRecord.value;
    const currentTimeValue = currentTime.value;

    //format and dispatch game data 
    if (countCorrectValue !== null) {
      var countCorrectLoad: number = +countCorrectValue;
      this.props.dispatch({
        type: countCorrectActionTypes.SET_COUNT,
        payload: countCorrectLoad
      });
    }
    if (currentQuestionIndexValue !== null) {
      var currentQuestionIndexLoad: number = +currentQuestionIndexValue + 1;
      this.props.dispatch({
        type: questionIndexActionTypes.SET_INDEX,
        payload: currentQuestionIndexLoad
      });
    }
    this.props.dispatch({
      type: categoryActionTypes.SET_CATEGORY,
      payload: currentCategoryValue
    });
    this.props.dispatch({
      type: difficultyActionTypes.SET_DIFFICULTY,
      payload: currentDifficultyValue
    });
    let currentQuestionsArrayLoad: Array<QuestionData>  = [];
    if (currentQuestionsArrayValue !== null) {
      currentQuestionsArrayLoad = JSON.parse(currentQuestionsArrayValue);
      this.props.dispatch({
        type: questionsArrActionTypes.SET_QUESTIONS_ARR,
        payload: currentQuestionsArrayLoad
      });
    }
    if (currentTimeValue !== null) {
      var currentTimeLoad: number = +currentTimeValue;
      this.props.dispatch({
        type: startTimeActionTypes.SET_TIME,
        payload: new Date().getTime() - currentTimeLoad
      });
    }
    if (scoreSubmittedValue !== null) {
      var scoreSubmittedLoad: boolean = scoreSubmittedValue === "true" ? true : false;
      this.props.dispatch({
        type: scoreSubmittedActionTypes.SET_SCORE_SUBMITTED,
        payload: scoreSubmittedLoad
      });
    }

    this.props.dispatch({
      type: quizInProgressActionTypes.SET_IN_PROGRESS
    });
  }

  async startNewGame() {
    const { platform, soundsOn, vibrationOn, uiAudio, focused } = this.props.store;
    
    //close load modal
    this.closeLoadModal();
    
    if (focused && soundsOn) {
      const uiPopAudio: Howl = new Howl({
        src: ['assets/pop.mp3'],
        format: ['mp3'],
        volume: 0.3,
        onloaderror: (id, e) => console.log('failed: ' + id + ' msg: ' + e)
    });
    uiPopAudio.play();
    } else {
      if (soundsOn) uiAudio.play();
    }

    if (platform !== 'web' && vibrationOn) Haptics.impact({style: ImpactStyle.Light});
    
    this.props.dispatch({
      type: quizInProgressActionTypes.SET_NOT_IN_PROGRESS
    });

    this.props.dispatch({
      type: questionsArrActionTypes.SET_QUESTIONS_ARR,
      payload: []
    });

    this.props.dispatch({
      type: countCorrectActionTypes.SET_COUNT,
      payload: 0
    });

    this.props.dispatch({
      type: questionIndexActionTypes.SET_INDEX,
      payload: 0
    })
    this.props.dispatch({
      type: scoreSubmittedActionTypes.SET_SCORE_SUBMITTED,
      payload: false
    });
    await this.timeout(20);
    this.props.callback();
  }

  render() {
    const { currentDifficulty, currentCategory, platform} = this.props.store;
    const { loadModalOpen } = this.state;
    return (
      <div className="ui-container">
        <div className="category-wrapper">
          <IonLabel className='ui-label'>CHOOSE A CATEGORY...</IonLabel>
          <IonSegment mode="md" scrollable value={ currentCategory } onIonChange={e => this.setCategory( String(e.detail.value) || currentCategory ) } className="categories">
            <div>
              <IonSegmentButton value="arts" layout="icon-start">
                <IonIcon icon={iconArts} />
                <IonLabel>Arts</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="food" layout="icon-start">
                <IonIcon icon={iconFood} />
                <IonLabel>Food</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="imported" layout="icon-start">
                <IonIcon icon={iconImported} />
                <IonLabel>Imported</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="random" layout="icon-start">
                <IonIcon icon={iconRandom} />
                <IonLabel>Random</IonLabel>
              </IonSegmentButton>
            </div><div>
              <IonSegmentButton value="brands" layout="icon-start">
                <IonIcon icon={iconBrands} />
                <IonLabel>Brands</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="imitative" layout="icon-start">
                <IonIcon icon={iconImitative} />
                <IonLabel>Imitative</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="money" layout="icon-start">
                <IonIcon icon={iconMoney} />
                <IonLabel>Money</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="sports" layout="icon-start">
                <IonIcon icon={iconSports} />
                <IonLabel>Sports</IonLabel>
              </IonSegmentButton> 
            </div>
          </IonSegment> 
        </div>
        <div className="difficulty-wrapper">
          <IonLabel className='ui-label'>...AND THE DIFFICULTY</IonLabel>
          <IonSegment mode="md" className="difficulty" value={ currentDifficulty } onIonChange={e => this.setQuestionDiff( String(e.detail.value) || 'easy' )}>
            <IonSegmentButton value="easy">
              <IonLabel>Easy</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="moderate">
              <IonLabel>Moderate</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="hard">
              <IonLabel>Hard</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </div>
        <IonModal id="warning"  isOpen={loadModalOpen}  canDismiss={true} onDidDismiss={this.closeLoadModal.bind(this)}>
            <div className="modal-wrapper">
              <div className="message-block">
                <div className="warning-message">
                  <b>A saved game was found.</b><br/>
                  Would you like to resume the saved game?
                </div>
                <IonButton className="yes" onClick={() => this.loadSavedGame()}>
                  YES
                </IonButton>
                <IonButton className="no" onClick={() => this.startNewGame()}>
                  NO
                </IonButton>
              </div>
            </div>
          </IonModal>
        <div className="button-wrapper">
          <IonButton onClick={() => this.checkSavedGame()} className="start-button">START GAME</IonButton>
        </div>
        <IonAlert
            isOpen={platform === 'web'}
            cssClass="size-alert"
            message={'The current window is too small to properly display and play the game. Please resize your browser window accordingly and enjoy!'}
            backdropDismiss={false}
          />
      </div>
    )
  }
};

export default withApplicationState(SetupUI);

// Global State Dispatch Actions

export const platformActionTypes = {
    SET_PLATFORM: 'SET_PLATFORM'
}

export const deviceActionTypes = {
    SET_DEVICE: 'SET_DEVICE'
}

export const uuidActionTypes = {
    SET_UUID: 'SET_UUID'
}

export const gameUuidActionTypes = {
    SET_GAME_UUID: 'SET_GAME_UUID'
}

export const soundsOnActionTypes = {
    SOUNDS_ON: 'SOUNDS_ON',
    SOUNDS_OFF: 'SOUNDS_OFF'
}

export const vibrationOnActionTypes = {
    VIBRATION_ON: 'VIBRATION_ON',
    VIBRATION_OFF: 'VIBRATION_OFF'
}

export const submissionsOnActionTypes = {
    SUBMISSIONS_ON: 'SUBMISSIONS_ON',
    SUBMISSIONS_OFF: 'SUBMISSIONS_OFF'
}

export const savedifficultyOnActionTypes = {
    SAVEDIFFICULTY_ON: 'SAVEDIFFICULTY_ON',
    SAVEDIFFICULTY_OFF: 'SAVEDIFFICULTY_OFF'
}

export const loadingScreenActionTypes = {
    SET_LOADING: 'SET_LOADING',
    SET_NOT_LOADING: 'SET_NOT_LOADING'
}

export const showProgressActionTypes = {
    SET_SHOW: 'SET_SHOW',
    SET_NOT_SHOW: 'SET_NOT_SHOW'
}

export const focusedActionTypes = {
    SET_FOCUSED: 'SET_FOCUSED',
    SET_NOT_FOCUSED: 'SET_NOT_FOCUSED'
}

export const questionsArrActionTypes = {
    SET_QUESTIONS_ARR: 'SET_QUESTIONS_ARR'
}

export const questionIndexActionTypes = {
    INCREMENT_INDEX: 'INCREMENT_INDEX',
    SET_INDEX: 'SET_INDEX'
}

export const startTimeActionTypes = {
    SET_TIME: 'SET_TIME'
}

export const scoreSubmittedActionTypes = {
    SET_SCORE_SUBMITTED: 'SET_SCORE_SUBMITTED'
}

export const difficultyActionTypes = {
    SET_DIFFICULTY: 'SET_DIFFICULTY'
}

export const categoryActionTypes = {
    SET_CATEGORY: 'SET_CATEGORY'
}

export const quizInProgressActionTypes = {
    SET_IN_PROGRESS: 'SET_IN_PROGRESS',
    SET_NOT_IN_PROGRESS: 'SET_NOT_IN_PROGRESS'
}

export const submittedActionTypes = {
    SET_SUBMITTED: 'SET_SUBMITTED',
    SET_NOT_SUBMITTED: 'SET_NOT_SUBMITTED'
}

export const correctResponseActionTypes = {
    SET_CORRECT: 'SET_CORRECT',
    SET_INCORRECT: 'SET_INCORRECT'
}

export const lastResponseActionTypes = {
    SET_RESPONSE: 'SET_RESPONSE'
}

export const lastQTextActionTypes = {
    SET_TEXT: 'SET_TEXT'
}

export const countCorrectActionTypes = {
    INCREMENT_COUNT: 'INCREMENT_COUNT',
    SET_COUNT: 'SET_COUNT'
}
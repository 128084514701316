import { Howl } from 'howler';

/**
 * App Constants
 */

export const NUM_QUESTIONS: number = 10;
export const ENVIRONMENT: string = 'dev';
export const NUM_OPTIONS: number = 4;
export const DEBUG: boolean = false;
export const CATEGORIES: Array<string> = [
    'brands',
    'sports',
    'money',
    'arts',
    'imitative',
    'food',
    'imported',

    // Future categories?
    // 'religious',
];
export const SUBCATEGORIES: Array<string> = [
    'animal',
    'apparel',
    'appearance',
    'birdcall',
    'book',
    'character',
    'currency',
    'dance',
    'deity',
    'music',
    'musical-instrument',
    'person',
    'place',
    'plant',
    'play',
    'trademark'
];

export const COUNT_SUBCATEGORIES: Array<string> = [
    'Animal',
    'Apparel',
    'Appearance',
    'Birdcall',
    'Book',
    'Character',
    'Currency',
    'Dance',
    'Deity',
    'Music',
    'Musical instrument',
    'Person',
    'Place',
    'Plant',
    'Play',
    'Trademark'
];

export const QUESTION_TYPES: Array<string> = [
    'date',
    'date-range-include',
    'date-range-exclude',
    'order',
    'category-include',
    'category-exclude',
    'first',
    'last',
    'origin-include',
    'origin-exclude',
    'origin-multi',
    'subcat-include',
    'subcat-exclude',
    'subcat-origin',
];
export const DEBUG_TYPES: Array<string> = [
    'date', 
    'date-range-include', 
    'date-range-exclude',
    'order', 
    'origin-include', 
    'origin-exclude', 
    'subcat-include',
    'subcat-exclude',
]

// String Array of available origin languages
// sorted by absolute frequency descending
export const ORIGIN_LANGUAGES: Array<string> = [
    'abenaki',
    'algonquian',
    'afrikaans',
    'arabic',
    'aramaic',
    'arawakan',
    'athabaskan',
    'australian',
    'balti',
    'bengali',
    'breton',
    'cariban',
    'catalan',
    'chinese',
    'cree',
    'croatian',
    'czech',
    'danish',
    'dharuk',
    'dutch',
    'finnish',
    'french',
    'german',
    'greek',
    'gujarati',
    'hawaiian',
    'hebrew',
    'hindi',
    'hungarian',
    'huron',
    'icelandic',
    'illinois',
    'ilocano',
    'inuit',
    'irish',
    'iroquoian',
    'italian',
    'japanese',
    'javanese',
    'korean',
    'latin',
    'lenape',
    'malay',
    'maori',
    'marquesan',
    'middle-english',
    'nahuatl',
    'narragansett',
    'norwegian',
    'occitan',
    'ojibwa',
    'old-english',
    'old-norse',
    'persian',
    'polish',
    'portuguese',
    'quechua',
    'romanian',
    'russian',
    'sanskrit',
    'scottish-gaelic',
    'scots',
    'serbian',
    'sinhala',
    'shona',
    'slovak',
    'spanish',
    'swahili',
    'swedish',
    'tagalog',
    'tahitian',
    'taino',
    'tamil',
    'tongan',
    'tupi',
    'turkish',
    'twi',
    'ukrainian',
    'urdu',
    'welsh',
    'yiddish',
    'zulu',
];

export const COMMON_ORIGIN_LINIAGES: Array<Array<string>> = [
    [
        'old-english',
        'scots',
        'middle-english'
    ],
    [
        'greek',
        'latin',
        'italian',
        'french',
        'middle-english'
    ],
    [
        'dutch',
        'german',
        'old-norse',
        'middle-english'
    ],
    [
        'arabic',
        'spanish',
        'french'
    ]
];

export const ARTICLES: Array<string> = [
    'a',
    'an',
    'the',
    'its'
];

export const COMMON_ORIGIN_LANGUAGES: Array<string> = [
    'french',
    'latin',
    'middle-english',
    'old-english',
    'old-norse',
    'dutch',
    'german',
    'scots',
    'swedish',
    'greek',
    'spanish'
];

export const uiPopAudio: Howl = new Howl({
    src: ['assets/pop.mp3'],
    format: ['mp3'],
    volume: 0.3,
    onloaderror: (id, e) => console.log('failed: ' + id + ' msg: ' + e)
});


/**
 * Constant variables for service authentication and id
 */

const apiVersion: string = '1.0';
export const appVersion: string = '5.0';
var api: string;
if (ENVIRONMENT === 'production') {
    api = 'https://wordigins.com/api/';
} else if (ENVIRONMENT === 'staging') {
    api = 'https://wordigins-staging.insightful.design/api/';
} else {
    api = 'https://wordigins-dev.insightful.design/api/';
}
export const apiURI: string = api + apiVersion;
export const apiSec: string = '8028c280461dbd703c1047208e716ec2b89239e558ea3bcf69dbe516d1e504ce';

export const firebaseAnalyticsWebOptions = {
    apiKey: "AIzaSyB7tkyd2WNbkULk090aXQ017LjFwSdJ-9o",
    authDomain: "wordigins-23830.firebaseapp.com",
    databaseURL: "https://wordigins-23830.firebaseio.com",
    projectId: "wordigins-23830",
    storageBucket: "wordigins-23830.appspot.com",
    messagingSenderId: "171085590345",
    appId: "1:171085590345:web:ddc052523749496c5b3498",
    measurementId: "G-0QQTHT6FF3"
}

export const adMobIds: {[key: string]: string} = {
    testBanner: 'ca-app-pub-3940256099942544/6300978111',
      
    androidAppId: 'ca-app-pub-6497266694062514~2915202139',
    androidBanner: 'ca-app-pub-6497266694062514/1494115730',

    iosAppId: 'ca-app-pub-6497266694062514~4356098708',
    iosBanner: 'ca-app-pub-6497266694062514/7975957120'
}

/*    
 * String Indexable!
 * Leaderboard keys must be of the form [category]_[difficulty]
 * for the global category and difficulty slugs
 */
export const leaderboardIds: {[key: string]: string} = {
    testLeaderboard:    'CgkIyd77q_0EEAIQAQ',

    random_easy:        'CgkIyd77q_0EEAIQAw',
    random_moderate:    'CgkIyd77q_0EEAIQBA',
    random_hard:        'CgkIyd77q_0EEAIQBQ',

    brand_easy:         'CgkIyd77q_0EEAIQBg',
    brand_moderate:     'CgkIyd77q_0EEAIQBw',
    brand_hard:         'CgkIyd77q_0EEAIQCA',

    arts_easy:          'CgkIyd77q_0EEAIQCQ',
    arts_moderate:      'CgkIyd77q_0EEAIQCg',
    arts_hard:          'CgkIyd77q_0EEAIQCw',

    sports_easy:        'CgkIyd77q_0EEAIQDA',
    sports_moderate:    'CgkIyd77q_0EEAIQDQ',
    sports_hard:        'CgkIyd77q_0EEAIQDg',

    money_easy:         'CgkIyd77q_0EEAIQEg',
    money_moderate:     'CgkIyd77q_0EEAIQEw',
    money_hard:         'CgkIyd77q_0EEAIQFA',

    imitative_easy:     'CgkIyd77q_0EEAIQDw',
    imitative_moderate: 'CgkIyd77q_0EEAIQEA',
    imitative_hard:     'CgkIyd77q_0EEAIQEQ'
}

// Disallowed 3-letter combinations taken from California license plate laws: https://www.governmentattic.org/7docs/CA-BannedPlates_2012.pdf
export const disallowedWords: Array<string> = [
    'ARS', 'ASS', 'COC', 'COK', 'CQC', 'CQK', 'CUM', 'CUN', 'DIC', 'DIE', 'DIK', 'DOA', 'FAG', 'FCK', 'FKU', 'FOC', 'FOK', 'FQC', 'FQK', 'FQU', 'FUC', 'FUD', 'FUG', 'FUK', 'FUX', 'GAT', 'HIV', 'JAP', 'KIK', 'KKK', 'KOC', 'KOK', 'KOX', 'KQC', 'KQK', 'KQX', 'KYK', 'NGR', 'NIG', 'PEE', 'PIS', 'SHT', 'SUE', 'SUK', 'SUX', 'TIT', 'VUE', 'VUK', 'VUX', 'WOP', 'WQP'
];
import React from 'react';
import { IonIcon } from '@ionic/react';
import '../theme/TopBar.css';

import withApplicationState from '../store';
import { ApplicationState } from '../ts/interfaces';

import { NUM_QUESTIONS } from '../ts/environment';

import logoTagline from '../assets/app_logo/logo_tagline.svg';
import iconRandom from '../assets/icons/random/icons_wordigins_random_brown_frame.svg';
import iconBrands from  '../assets/icons/brand/icons_wordigins_brand_brown_frame.svg';
import iconSports from  '../assets/icons/sports/icons_wordigins_sports_brown_frame.svg';
import iconArts   from  '../assets/icons/arts/icons_wordigins_arts_brown_frame.svg';
import iconReligious from '../assets/icons/religion/icons_wordigins_religion_brown_frame.svg';
import iconMoney from '../assets/icons/money/icons_wordigins_money_brown_frame.svg';
import iconImitative from '../assets/icons/imitative/icons_wordigins_imitative_brown_frame.svg';
import iconFood from '../assets/icons/food/icons_wordigins_food_brown_frame.svg';
import iconImported from '../assets/icons/imported/icons_wordigins_imported_brown_frame.svg';
import iconClose from '../assets/icons/ui/close_x.svg';

interface TopBarProps {
    showClose?:                boolean;
    showLogo?:                 boolean;
    showCategory?:             boolean;
    iosBuffer?:                boolean;
    store:             ApplicationState;
    dispatch:          ({ type }: { type: string; payload?: any; }) => void;
    categoryCallback:     () => void;
    closeCallback:     () =>     void;
}

interface TopBarState { }

class TopBar extends React.Component<TopBarProps, TopBarState> {


    getQuestionIndex() {
        let index;
        const { currentQuestionIndex } = this.props.store;
        if ( currentQuestionIndex ) { 
            if (currentQuestionIndex! !== NUM_QUESTIONS) {
                 index = currentQuestionIndex + 1;
              } else {
                index = NUM_QUESTIONS; 
              }
        } else {
            index = 1;
        }
        return index;
    }

    render () {
        const { currentCategory, quizInProgress, loadingScreen, showProgress } = this.props.store;
        const { categoryCallback, showClose, showLogo, showCategory, closeCallback, iosBuffer} = this.props;

        let categoryIcon;
        switch (currentCategory) {
                case 'brands':
                    categoryIcon = iconBrands;
                    break;
                case 'sports':
                    categoryIcon = iconSports;
                    break;
                case 'arts':
                    categoryIcon = iconArts;
                    break;
                case 'religious':
                    categoryIcon = iconReligious;
                    break;
                case 'money':
                    categoryIcon = iconMoney;
                    break;
                case 'imitative':
                    categoryIcon = iconImitative;
                    break;
                case 'food':
                    categoryIcon = iconFood;
                    break;
                case 'imported':
                    categoryIcon = iconImported;
                    break;
                default:
                    categoryIcon = iconRandom;
        }

        var topBarClass = "";
        (iosBuffer) ? 
            topBarClass = "top-bar ios-buffer"
            :
            topBarClass = "top-bar"

        return (
            <div className={topBarClass}>
                <div className="icon-wrapper">
                    {(showCategory) ?
                        <img className="ui-icon" src={categoryIcon} alt=""></img>
                        :
                        <></>
                    }
                </div>
                {(showLogo) ?
                    <IonIcon className="logo" icon={logoTagline} />
                    :
                    <></>
                }
                {(showClose) ?
                    <div className="icon-wrapper-right">
                        <IonIcon className="close-icon" icon={iconClose} onClick={() => closeCallback()} />
                    </div>
                    :
                    <div className="question-index">
                        {( loadingScreen ) ? 
                            <h1> 1/{ NUM_QUESTIONS } </h1>
                            : 
                            ( quizInProgress ) ? 
                                ( showProgress ) ? 
                                    <h1> {this.getQuestionIndex()}/{ NUM_QUESTIONS } </h1>
                                    : 
                                    <></>
                            : 
                            <></>
                        }
                    </div>
                }
            </div>
        );
    }
}

export default withApplicationState(TopBar);
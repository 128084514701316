import React from 'react';
import '../theme/NoInternetWarning.css';
import { IonModal, IonButton } from '@ionic/react';


import withApplicationState from '../store';
import { ApplicationState } from '../ts/interfaces';


interface NoInternetWarningProps {
    store:             ApplicationState;
    homeCallback:     () =>     void;
    internetModalOpen:  boolean;
}

interface NoInternetWarningState { }

class NoInternetWarning extends React.Component<NoInternetWarningProps, NoInternetWarningState> {
    render () {
        const { homeCallback, internetModalOpen } = this.props;
        return (
            <IonModal
            id="internet-warning"
            isOpen={internetModalOpen}
            canDismiss={true}
            onDidDismiss={() => homeCallback()}
          >
            <div className="modal-wrapper">
              <div className="message-block">
                <div className="warning-message">
                  <b>
                    It appears you've lost your internet connection. Please re-establish your
                    connection and try again.
                  </b>
                  <br />
                </div>
                <IonButton className="internet-warning-ok" onClick={() => homeCallback()}>
                  OK
                </IonButton>
              </div>
            </div>
          </IonModal>
        );
    }
}

export default withApplicationState(NoInternetWarning);
/**
 * Home page component, for modals and other components that need global scope
 */

import React from 'react';
import { IonContent, IonPage, IonIcon, IonModal, IonList, IonLabel, 
         IonToggle, CreateAnimation } from '@ionic/react';
import { Preferences } from '@capacitor/preferences';
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import axios from 'axios';
import { apiURI, appVersion, ENVIRONMENT } from '../ts/environment';
import signReq from '../ts/signRequest';

import './Home.css';

import withApplicationState from '../store';
import { ApplicationState, scoresRefresh } from '../ts/interfaces';
import AdMobFuncs from '../ts/adMob';
import { quizInProgressActionTypes, 
         difficultyActionTypes, 
         submittedActionTypes, 
         soundsOnActionTypes, 
         vibrationOnActionTypes, 
         submissionsOnActionTypes,
         savedifficultyOnActionTypes,
         scoreSubmittedActionTypes } from '../ts/actionTypes';

import LoopController   from '../components/LoopController';
import TopBar           from '../components/TopBar';
import BottomBar        from '../components/BottomBar';
import Scores           from '../components/Scores';

import logoTagline from '../assets/app_logo/logo_tagline.svg';
import "@capacitor-community/firebase-analytics";

interface HomeProps {
  store:             ApplicationState;
  dispatch:          ({ type }: { type: string; payload?: any; }) => void;
};

interface HomeState {
  settingsOpen:           boolean;
  scoresOpen:             boolean;
  infoOpen:               boolean;
  soundsChecked:          boolean;
  vibrationChecked:       boolean;
  submitChecked:          boolean;
  savedifficultyChecked:  boolean;
};


class Home extends React.Component<HomeProps, HomeState> {

  constructor (props: HomeProps) {
    super(props);

    const { soundsOn, vibrationOn, submissionsOn, savedifficultyOn } = this.props.store;

    this.state = {
      settingsOpen:           false,
      scoresOpen:             false,
      infoOpen:               false,
      soundsChecked:          soundsOn,
      vibrationChecked:       vibrationOn,
      submitChecked:          submissionsOn,
      savedifficultyChecked:  savedifficultyOn,
    }

    //console.log("SOUNDS ON: " + soundsOn);

    // Handle hardware back button same as home button
    document.addEventListener('ionBackButton', (event: any) => {
      event.detail.register(10, () => {
        this.homeCallback();
      });
    });
  }

  async componentDidMount() {
    const { platform } = this.props.store;

    // Set persistent settings here, note platform availability
    
    // Turn off score submissions if user has turned them off previously
    if ((await Preferences.get({key: 'submissionsOn'})).value === '0') {
      this.props.dispatch({
        type: submissionsOnActionTypes.SUBMISSIONS_OFF
      });

      this.setState({submitChecked: false});
    }

    // Turn off sounds and vibrations if user has turned them off previously
    if ((await Preferences.get({key: 'soundsOn'})).value === '0') {
      this.props.dispatch({
        type: soundsOnActionTypes.SOUNDS_OFF
      });
      
      this.setState({soundsChecked: false});
    }
    
    if (platform !== 'web') {
      if ((await Preferences.get({key: 'vibrationOn'})).value === '0') {
        this.props.dispatch({
          type: vibrationOnActionTypes.VIBRATION_OFF
        });
      
        this.setState({vibrationChecked: false}); 
      }

    }

    // Turn off loading last session's difficulty level if user has turned them off previously
    if ((await Preferences.get({key: 'savedifficultyOn'})).value === '0') {
      this.props.dispatch({
        type: savedifficultyOnActionTypes.SAVEDIFFICULTY_OFF
      });

      this.setState({savedifficultyChecked: false});
    }
    
    // Set difficulty to previoulsy selected value if user has chosen to save difficulty between sessions previously
    if ((await Preferences.get({key: 'savedifficultyOn'})).value === '1' || (await Preferences.get({key: 'savedifficultyOn'})).value === null) {
      const lastDiff = (await Preferences.get({key: 'difficulty'})).value;
      if (lastDiff) {
        this.props.dispatch({
          type: difficultyActionTypes.SET_DIFFICULTY,
          payload: lastDiff
        });
      }
    }
  }

  private splashAnimationRef: React.RefObject<CreateAnimation> = React.createRef();

  showBannerHome () {
    const { settingsOpen, scoresOpen, infoOpen } = this.state;
    if (!settingsOpen && !scoresOpen && !infoOpen) {
      AdMobFuncs.resumeBanner();
    }
  }

  closeAll () {
    this.setState({
      settingsOpen: false,
      scoresOpen:   false,
      infoOpen: false
    });
  }

  openSettings () {
    this.setState({
      scoresOpen:   false,
      infoOpen:     false,
      settingsOpen: true,
    });
  }

  closeSettings () {
    this.setState({
      settingsOpen: false
    });
  }

  async refreshScores () {
    const { uuid } = this.props.store;
    let date = Math.round(+new Date()/1000).toString();
    let refreshBody: scoresRefresh = {
    // data required by API
    version: appVersion,
    sig: signReq(uuid, date),
    timestamp: date
  }
    try {
      let response = await axios.post(apiURI +'/scores/Refresh', refreshBody, ENVIRONMENT !== 'production' ? { auth: {username: "igins", password: "llc"}} : {});
  } catch (e) {
      throw(e);
  }
  }
  openScores () {
    this.refreshScores()
    this.setState({
      settingsOpen: false,
      infoOpen: false,
      scoresOpen:   true
    });
  }

  closeScores () {
    const { platform, quizInProgress } = this.props.store;
    this.setState({
      scoresOpen: false
    });
  }

  openInfo () {
    this.setState({
      settingsOpen: false,
      scoresOpen: false,
      infoOpen:   true
    });
    this.handleScroll();
  }

  closeInfo () {
    this.setState({
      infoOpen: false
    });
  }

  endQuiz () {
    const { currentQuestionIndex, currentQuestionsArray, countCorrect, platform, lastQText } = this.props.store;

    let lastQuestion = (currentQuestionIndex !== undefined) ? currentQuestionsArray[currentQuestionIndex ?? 0] : undefined;
    let lastNum = (currentQuestionIndex) ? currentQuestionIndex : 0;

    //console.log('GAME UUID IN Quiz Quit Early');
    //console.log(this.props.store.gameUuid);

    if (ENVIRONMENT === 'production') {
      FirebaseAnalytics.logEvent({
        name: 'quiz_quit_early',
        params: {
          game_uuid: this.props.store.gameUuid,
          count_correct: countCorrect,
          count_answered: lastNum,
          lastq_text: lastQText,
          lastq_type: lastQuestion?.type,
          lastq_subject: lastQuestion?.subject,
          lastq_subject_diff: lastQuestion?.subjectDiff,
          lastq_answer: lastQuestion?.answer,
          lastq_options: lastQuestion?.options.join(','),
          lastq_range: lastQuestion?.range
        }
      });
    }

    // console.log({
    //   game_uuid: this.props.store.gameUuid,
    //   count_correct: countCorrect,
    //   count_answered: lastNum,
    //   lastq_text: lastQText,
    //   lastq_type: lastQuestion?.type,
    //   lastq_subject: lastQuestion?.subject,
    //   lastq_subject_diff: lastQuestion?.subjectDiff,
    //   lastq_answer: lastQuestion?.answer,
    //   lastq_options: lastQuestion?.options.join(','),
    //   lastq_range: lastQuestion?.range
    // });
    //console.log(lastQuestion);
    //console.log('currentQuestionIndex');
    //console.log(currentQuestionIndex);
    //console.log(currentQuestionsArray[currentQuestionIndex ?? 0]);
    //console.log(currentQuestionsArray);

    this.props.dispatch({
      type: quizInProgressActionTypes.SET_NOT_IN_PROGRESS
    });

    this.props.dispatch({
      type: submittedActionTypes.SET_NOT_SUBMITTED
    });

    if (platform !== 'web') AdMobFuncs.resumeBanner();
  }

  homeCallback () {
    const { quizInProgress } = this.props.store;

    this.closeAll();
    if (quizInProgress) {
      this.endQuiz();
    }

    //show scores button
    this.props.dispatch({
      type: scoreSubmittedActionTypes.SET_SCORE_SUBMITTED,
      payload: true
    });
  }


  setQuestionDiff (qDiff: string) {
    this.props.dispatch({
      type: difficultyActionTypes.SET_DIFFICULTY,
      payload: qDiff
    });
  }

  setSounds (checked: boolean) {
    //console.log('SET SOUNDS: ' + checked);

    this.setState({
      soundsChecked: checked
    });

    if (checked) {
      this.props.dispatch({
        type: soundsOnActionTypes.SOUNDS_ON
      });
    } else {
      this.props.dispatch({
        type: soundsOnActionTypes.SOUNDS_OFF
      });
    }
  }

  setVibration (checked: boolean) {
    this.setState({
      vibrationChecked: checked
    });

    if (checked) {
      this.props.dispatch({
        type: vibrationOnActionTypes.VIBRATION_ON
      });
    } else {
      this.props.dispatch({
        type: vibrationOnActionTypes.VIBRATION_OFF
      });
    }
  }

  setSubmit (checked: boolean) {
    this.setState({
      submitChecked: checked
    });

    if (checked) {
      this.props.dispatch({
        type: submissionsOnActionTypes.SUBMISSIONS_ON
      });
    } else {
      this.props.dispatch({
        type: submissionsOnActionTypes.SUBMISSIONS_OFF
      });
    }
  }

  setsaveDifficulty (checked: boolean) {
    this.setState({
      savedifficultyChecked: checked
    });

    if (checked) {
      this.props.dispatch({
        type: savedifficultyOnActionTypes.SAVEDIFFICULTY_ON
      });
    } else {
      this.props.dispatch({
        type: savedifficultyOnActionTypes.SAVEDIFFICULTY_OFF
      });
    }
  }

  handleScroll = () => {
    if (document.getElementById("marketing_text_wrapper")) {
      //Hide scroll indicator if at bottom
      if (((document.getElementById("marketing_text_wrapper")!.scrollTop + document.getElementById("marketing_text_wrapper")!.clientHeight) * 1.1) >= document.getElementById("marketing_text_wrapper")!.scrollHeight) {
        if (document.getElementById("marketing_scroll_indicator")) {
          document.getElementById("marketing_scroll_indicator")!.style.borderLeft = '0px';
          document.getElementById("marketing_scroll_indicator")!.style.borderBottom = '0px';
        }
      } else {

        //show scroll indicator
        if (document.getElementById("marketing_scroll_indicator")) {
          document.getElementById("marketing_scroll_indicator")!.style.borderLeft = '2px solid #fff';
          document.getElementById("marketing_scroll_indicator")!.style.borderBottom = '2px solid #fff';
      }
      } 
    }
  }

  render () {
    const { settingsOpen, scoresOpen, soundsChecked, vibrationChecked, submitChecked, infoOpen, savedifficultyChecked } = this.state;
    const { currentQuestionIndex, currentQuestionsArray, quizInProgress, platform, device, uuid, loadingScreen } = this.props.store;


    if (quizInProgress && currentQuestionIndex === currentQuestionsArray.length && currentQuestionIndex !== 0) {
      if (platform !== 'web') AdMobFuncs.hideBanner();
    }

    return (
      <IonPage>
        <IonContent id="content-id" no-bounce has-bouncing={false} overflow-scroll={false} forceOverscroll={false} >
          <IonModal className="modal-fullscreen" id="info"   isOpen={infoOpen}   canDismiss={true}>
            <div className={"info-modal-wrapper"}>
              <div className="info-wrapper">
              { platform !== 'web' ? <div className='ad-buffer'></div> : <></> }
                <div className="info-overlay">
                  { (quizInProgress) ?
                    <div>
                      <TopBar categoryCallback={() => {/** Dummy Callback */}}
                              closeCallback={this.closeAll.bind(this)}
                              showCategory={true}
                              showLogo={true}
                              showClose={true} />
                      <div className="top-bar-info">
                        <div>CATEGORY<br/>CHOSEN</div>
                        <div id="question-number"> CLOSE<br/>WINDOW</div>
                      </div>
                    </div>
                    :
                    <div>
                      <TopBar categoryCallback={() => {/** Dummy Callback */}}
                              closeCallback={this.closeAll.bind(this)}
                              showCategory={false}
                              showLogo={false}
                              showClose={true} />
                      <div className="top-bar-info">
                      <div><br/></div>
                        <div id="question-number"> CLOSE<br/>WINDOW</div>
                      </div>
                    </div>
                    }
                    <div>
                      <IonIcon icon={logoTagline} className="modal-logo"/>
                      <div className="scroll-text-wrapper" >
                        <div id="marketing_text_wrapper" className="marketing-text-wrapper" onScroll={() => this.handleScroll()} >
                        <p>We love feedback, so please share yours!
                        <ul>
                          <li>Have a word you'd like us to add to our Wordigins game? <a href="https://wordigins.com/submit-a-word/">Submit a word here.</a><br></br></li>
                          <li>Have an issue with any of the words in our game? Think we're missing a key piece of information, or want to suggest an alternate etymology? <a href="https://wordigins.com/report-a-word/">Report a word here.</a><br></br></li>
                          <li>Want to tell us anything else about what you love or hate about Wordigins? Want to suggest new game features? Or just wish something was different? <a href="https://wordigins.com/contact-us/">Tell us about that here.</a><br></br></li>
                        </ul>
                        Learn about English word origins and test your knowledge! Propose new words at <a href="https://wordigins.com/">wordigins.com</a>.<br></br><br></br>
                        Wordigins (short for word origins) allows you to test your lexical knowledge with more than 1,500 words spanning eight categories including arts, food, money, and sports. <br></br><br></br>
                        Modern English itself is less than 700 years old. It emerged in its standardized form with the arrival of the printing press in the 1400s from the dialect spoken in the East Midlands region of England, home of Oxford and Cambridge universities. English mutated further with its spread to the Americas and beyond; its story is one of conquest and defeat, colonization and commerce. Wordigins uses American English.<br></br><br></br><a id="privacy_policy_link" href="https://wordigins.com/privacy-policy/">Privacy Policy</a></p>
                        </div>
                        <div onClick={() => document.getElementById("marketing_text_wrapper")!.scrollTo({
                              top: document.getElementById("marketing_text_wrapper")!.scrollTop + document.getElementById("marketing_text_wrapper")!.clientHeight * .85,
                              behavior: "smooth"
                            })} id="marketing_scroll_indicator" className="marketing-scroll-indicator"></div>
                      </div>  
                    </div>
                    <div>
                      <div>
                        <div className="bottom-bar-info">
                          <div></div>
                          <div className="home-label">HOME</div>
                          <div className="info-label">INFO</div>
                          <div className="scores-label">SCORES</div>
                          <div className="settings-label">SETTINGS</div>
                          <div></div>
                        </div>
                      </div>
                        <BottomBar home={currentQuestionIndex !== currentQuestionsArray.length}
                                  info={currentQuestionIndex !== currentQuestionsArray.length}
                                  infoActive={true}
                                  scores={!quizInProgress || currentQuestionIndex === currentQuestionsArray.length}
                                  settings={true} 
                                  scoresActive={false}
                                  settingsCallback={this.openSettings.bind(this)} 
                                  infoCallback={this.closeInfo.bind(this)} 
                                  homeCallback={this.homeCallback.bind(this)}
                                  achievementsCallback={this.openScores.bind(this)}/>
                    </div>
                  </div>
                </div>
              </div>
            </IonModal>
          <IonModal className="modal-fullscreen" id="settings" isOpen={settingsOpen} canDismiss={true} onDidDismiss={this.closeSettings.bind(this)}>
            { platform !== 'web' ? <div className='ad-buffer'></div> : <></> }
            <div className="background">
              <div className="words">
                <div className="overlay"> 
                  <div className={"modal-wrapper"}>
                    <div className="topbar-wrapper">
                    <TopBar categoryCallback={() => {/** Dummy Callback */}}
                                  closeCallback={this.closeAll.bind(this)}
                                  showCategory={false}
                                  showLogo={false}
                                  showClose={true} />
                    </div>
                    <div>
                      <IonIcon icon={logoTagline} className="modal-logo"/>
                      <p>You use thousands of them every day, but how often do you ponder their origins? Words, the cells in the lifeblood of the English language.</p>
                    </div>
                    <div>
                      <div className="settings-list-wrapper">
                        <IonList className="settings-list">
                          <div className="toggle-wrapper">
                            <IonLabel>SUBMIT SCORES</IonLabel>
                            <IonToggle checked={submitChecked} onIonChange={e => this.setSubmit(e.detail.checked)} />
                          </div>
                          <div className="toggle-wrapper">
                            <IonLabel>SOUND EFFECTS</IonLabel>
                            <IonToggle checked={soundsChecked} onIonChange={e => this.setSounds(e.detail.checked)} />
                          </div>
                          {(platform !== 'web' && !device.toLowerCase().includes("iPad".toLowerCase())) ? 
                            <div className="toggle-wrapper">
                              <IonLabel>VIBRATION</IonLabel>
                              <IonToggle checked={vibrationChecked} onIonChange={e => this.setVibration(e.detail.checked)} />
                            </div>
                            : <></>
                          }
                          <div className="toggle-wrapper">
                            <IonLabel>REMEMBER DIFFICULTY</IonLabel>
                            <IonToggle checked={savedifficultyChecked} onIonChange={e => this.setsaveDifficulty(e.detail.checked)} />
                          </div>
                          {(platform !== 'web') ? 
                            <IonLabel><a href="https://wordigins.com/contact-us/">CONTACT US</a></IonLabel>
                            : <></>
                        }
                        </IonList>
                      </div>
                      <div className="copyright">
                        <span>Version 2.4.5d </span>
                        { (new Date().getFullYear().toString() === '2023') ?
                          <span>© {new Date().getFullYear()}.  Igins LLC. All Rights Reserved.</span>
                          :
                          <span>© 2023-{new Date().getFullYear()}.  Igins LLC. All Rights Reserved.</span>
                      }
                      </div>
                      <div className="bottombar-wrapper">
                        <BottomBar home={currentQuestionIndex !== currentQuestionsArray.length}
                                  info={currentQuestionIndex !== currentQuestionsArray.length}
                                  scores={!quizInProgress || currentQuestionIndex === currentQuestionsArray.length}
                                  settings={true}
                                  infoActive={false}
                                  settingsActive={true} 
                                  scoresActive={false}
                                  settingsCallback={this.closeSettings.bind(this)} 
                                  infoCallback={this.openInfo.bind(this)} 
                                  homeCallback={this.homeCallback.bind(this)} 
                                  achievementsCallback={this.openScores.bind(this)}/>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </IonModal>
          <IonModal className="modal-fullscreen" id="scores"   isOpen={scoresOpen}   canDismiss={true} onDidDismiss={this.closeScores.bind(this)}>
            { platform !== 'web' ? <div className='ad-buffer'></div> : <></> }
            <div className={"modal-wrapper"}>
              <div className="scores-wrapper">
                <div className="topbar-wrapper">
                  <TopBar categoryCallback={() => {/** Dummy Callback */}}
                                closeCallback={this.closeAll.bind(this)}
                                showCategory={true}
                                showLogo={true}
                                showClose={true} />
                </div>
                <Scores 
                  startGameCallback={this.closeAll.bind(this)}/>
                <div className="bottombar-wrapper">
                  <BottomBar home={currentQuestionIndex !== currentQuestionsArray.length}
                            info={currentQuestionIndex !== currentQuestionsArray.length}
                            scores={!quizInProgress || currentQuestionIndex === currentQuestionsArray.length}
                            settings={true}
                            infoActive={false}
                            scoresActive={true}
                            settingsCallback={this.openSettings.bind(this)} 
                            infoCallback={this.openInfo.bind(this)} 
                            homeCallback={this.homeCallback.bind(this)}
                            achievementsCallback={this.closeScores.bind(this)}/>
                </div>
              </div>
            </div>
          </IonModal>
          <div className="background">
            <div className="words">
              <div className="overlay">
              { (quizInProgress || loadingScreen) ?
                <div className="topbar-wrapper">
                  { (currentQuestionIndex !== currentQuestionsArray.length || (currentQuestionIndex === 0 && currentQuestionsArray.length === 0)) ?
                    platform !== 'web' ? 
                      <div className='ad-buffer'></div> 
                      : 
                      <></>
                    :
                    <></>}
                  { (currentQuestionIndex !== currentQuestionsArray.length || (currentQuestionIndex === 0 && currentQuestionsArray.length === 0)) ?
                    <TopBar categoryCallback={() => {/** Dummy Callback */}}
                      showCategory={true}
                      showLogo={true}
                      showClose={false} 
                      iosBuffer={false}/>
                    :
                    <TopBar categoryCallback={() => {/** Dummy Callback */}}
                      showCategory={true}
                      showLogo={true}
                      showClose={false} 
                      iosBuffer={true}/>}
                  </div>
                  :
                  <></>
                }
                <LoopController splashAnimationRef={this.splashAnimationRef} openScores={this.openScores.bind(this)} />
                <CreateAnimation
                  ref={this.splashAnimationRef}
                  duration={1000}
                  iterations={1}
                  fromTo={[{ property: "opacity", fromValue: "0", toValue: "1" }]}>
                  <div className="bottom-bar-animation-wrapper">
                    <div className="bottombar-wrapper">
                      <BottomBar home={quizInProgress && currentQuestionIndex !== currentQuestionsArray.length}
                                info={currentQuestionIndex !== currentQuestionsArray.length}
                                scores={!quizInProgress || currentQuestionIndex === currentQuestionsArray.length}
                                settings={true}
                                infoActive={false} 
                                settingsCallback={this.openSettings.bind(this)} 
                                infoCallback={this.openInfo.bind(this)} 
                                homeCallback={this.homeCallback.bind(this)}
                                achievementsCallback={this.openScores.bind(this)}/>
                    </div>
                  </div>
                </CreateAnimation>
              </div>
            </div>
          </div>
        </IonContent>
      </IonPage>
    );
  }
};

export default withApplicationState(Home);

import React from 'react';
import { createRoot } from 'react-dom/client';
import Wordigins from './App';
import { StateProvider } from './store';
import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
    <StateProvider>
        <Wordigins />
    </StateProvider>);

// ReactDOM.render(
//     <StateProvider>
//         <Wordigins />
//     </StateProvider>, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

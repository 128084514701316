import React from 'react';
import { CreateAnimation, IonImg } from '@ionic/react';
import '../theme/LoopController.css';

import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import '@capacitor-community/firebase-analytics';

import { NUM_QUESTIONS, ENVIRONMENT } from '../ts/environment';

import withApplicationState from '../store';
import { countCorrectActionTypes, questionIndexActionTypes, questionsArrActionTypes, quizInProgressActionTypes, loadingScreenActionTypes, startTimeActionTypes, scoreSubmittedActionTypes, gameUuidActionTypes } from '../ts/actionTypes';
import { ApplicationState, QuestionData } from '../ts/interfaces';
import { getQuestionsArr } from '../ts/questionBuilders';
import AdMobFuncs from '../ts/adMob';

import SplashScreen from './SplashScreen';
import SetupUI      from './SetupUI';
import Question     from './Question';
import Review       from './Review';
import Summary      from './Summary';
import NoInternetWarning    from '../components/NoInternetWarning';


import loadingGif from '../assets/loading.gif';

import * as uuid from 'uuid';

interface LoopControllerProps { 
  store:             ApplicationState;
  dispatch:          ({ type }: { type: string; payload?: any; }) => void;

  splashAnimationRef:    React.RefObject<CreateAnimation>;
  openScores: () => void;
};

interface LoopControllerState {
  splash:                 boolean;
  splashSkipped:          boolean;
  adPresent:              boolean;
  adBuffer:               boolean;
  loading:                boolean;
  internetModalOpen:      boolean;

  wordDifficulty:         string | undefined;
}

class LoopController extends React.Component<LoopControllerProps, LoopControllerState> {
  constructor(props: LoopControllerProps) {
    super(props);

    this.state = {
      splash:                 true,
      splashSkipped:          false,
      adPresent:              false,
      adBuffer:               true,
      loading:                false,
      internetModalOpen:      false,
      
      wordDifficulty:         undefined,
    };
  }

  openInternetModal () {
    this.setState({
      internetModalOpen:   true,
    });
  }

  closeInternetModal () {
    console.log("closed internet modal");
    this.setState({
      internetModalOpen:   false,
    });
  }
  
  private UIFadeInRef: React.RefObject<CreateAnimation> = React.createRef();
  async splashCallback() {
    const { splashSkipped } = this.state;
    const { platform } = this.props.store;


    if (this.UIFadeInRef.current && this.props.splashAnimationRef.current && !splashSkipped ) {
      const bottomBarFadeIn = this.props.splashAnimationRef.current.animation;
      const UIFadeIn = this.UIFadeInRef.current.animation;
    
      try {
        await Promise.all([UIFadeIn.play(), bottomBarFadeIn.play()]);
      } catch (e) {
        console.error(e);
      }
      this.setState({splash: false});
    }

    AdMobFuncs.resumeBanner();
    if (platform === 'web')  {
      // hide banner on web after splash animation is finished
      AdMobFuncs.hideBanner();
      this.setState({adBuffer: false});
    }
  }

  /**
   * Procedure to skip the splash screen 
   * keeps wordigins animation playing in final location of logo
   */
  skipSplash() {
    const { splash } = this.state;
    const bottomBarFadeIn = this.props.splashAnimationRef.current!.animation;
    const { platform } = this.props.store;


    if (platform === 'web')  {
      // hide banner on web immediately if splash animation is skipped
      this.setState({adBuffer: true});
    }

    if (splash) {
      bottomBarFadeIn.play();
    }

    this.setState({splashSkipped: true, splash: false});
  }

  /**
   * Procedure to build all questions for a quiz using current difficulty and category
   */
  private async buildQuestions() {
    const { uuid, currentDifficulty, currentCategory, platform } = this.props.store;
    let retryCount = 0;
    let debugWords: Array<string> = [];
    if ( platform === 'web' ) {
      let url = new URL(window.location.href);
      let params = Object.fromEntries(Array(...url.searchParams.entries()));

      if (params.debug) {
        debugWords = params.debug.split(',');
      }
    }
    const getQsWithRetry = async () => {
      try {
          let questionsArrInner = await getQuestionsArr(uuid, NUM_QUESTIONS, currentDifficulty, currentCategory, debugWords);
          return questionsArrInner;
      } catch (error: any) {
          if (error.code) {
              // Retry the request after a delay (e.g., 2 seconds)
              retryCount = retryCount + 1;
              if ( retryCount === 7 ) {
                this.openInternetModal();
              }
              await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 2 seconds
              await getQsWithRetry(); // Retry the request
          } else {
              // Throw the error if it's not related to network connectivity
              throw error;
          }
      }
  }
  
    let questionsArr: void | QuestionData[] | undefined = [];
    questionsArr = await getQsWithRetry().catch(error => console.error("Request failed:", error));

    let questionsArrLength = questionsArr?.length;
    while ( questionsArrLength !== NUM_QUESTIONS ) {
      questionsArr = await getQsWithRetry().catch(error => console.error("Request failed:", error));
      questionsArrLength = questionsArr?.length;
    }

    if (questionsArr) {

      this.closeInternetModal();
      this.props.dispatch({
        type: loadingScreenActionTypes.SET_NOT_LOADING
      });

      this.setState({
        loading: false,
      });
      
      this.props.dispatch({
        type: questionsArrActionTypes.SET_QUESTIONS_ARR,
        payload: questionsArr
      });

      this.props.dispatch({
        type: questionIndexActionTypes.SET_INDEX,
        payload: 0
      });
      this.props.dispatch({
        type: startTimeActionTypes.SET_TIME,
        payload: new Date().getTime()
      });
    }
  }

  /**
   * Procedure for setting up a new game
   * @param reset whether or not to reset for a fresh quiz (used for callbacks from summary view)
   */
  setupCallback(reset: boolean = false) {
    const { currentDifficulty, currentCategory, platform } = this.props.store;

    // Generate new UUID for each quiz
    let gameUuid = uuid.v4();

    this.props.dispatch({
      type: gameUuidActionTypes.SET_GAME_UUID, 
      payload: gameUuid
    });

    //console.log('gameUuid');
    //console.log(gameUuid);

    if (ENVIRONMENT === 'production') {
      FirebaseAnalytics.logEvent({
        name: 'quiz_started',
        params: {
          game_uuid: gameUuid,
          category: currentCategory,
          difficulty: currentDifficulty
        }
      });
    }
    
    // Reset global variables before starting new quiz
    if (reset) {
      this.props.dispatch({
        type: quizInProgressActionTypes.SET_NOT_IN_PROGRESS
      });
  
      this.props.dispatch({
        type: questionsArrActionTypes.SET_QUESTIONS_ARR,
        payload: []
      });
  
      this.props.dispatch({
        type: countCorrectActionTypes.SET_COUNT,
        payload: 0
      });

      this.props.dispatch({
        type: gameUuidActionTypes.SET_GAME_UUID, 
        payload: ''
      });
    }

    this.props.dispatch({
      type: loadingScreenActionTypes.SET_LOADING
    });

    this.props.dispatch({
      type: scoreSubmittedActionTypes.SET_SCORE_SUBMITTED,
      payload: false
    });

    this.setState({
        loading: true,
      }, 
      // call as callback after state is set
      () => this.buildQuestions()
    );

    this.props.dispatch({
      type: quizInProgressActionTypes.SET_IN_PROGRESS
    });;
  }

  render() {
    const { splash, splashSkipped, adBuffer, loading, internetModalOpen } = this.state;
    const { quizInProgress, currentQuestionsArray, currentQuestionIndex, submitted } = this.props.store;
    const numQuestions = currentQuestionsArray.length;
    
    if (quizInProgress && numQuestions === NUM_QUESTIONS && currentQuestionIndex !== undefined) {
      // Quiz loop components
      if (currentQuestionIndex === numQuestions) {
        return (
          <Summary playAgainCallback={this.setupCallback.bind(this)} openScores={this.props.openScores} />
        );
      } else if (submitted) {
        return (
          <Review />
        );
      } else {
        return (
          <Question />
        );
      }

    } else if (loading) {
      // Loading Screen
      return (
        <>
          <div className='loading'><IonImg src={loadingGif} /></div>
          <NoInternetWarning internetModalOpen={internetModalOpen}
                             homeCallback={this.closeInternetModal.bind(this)}/>
      </>
      );
    } else {
      console.log(splash, splashSkipped);
      if (!splash && !splashSkipped) {
        AdMobFuncs.resumeBanner();
      }
      // Splash screen and setup UI
      return (
        <>
          {(adBuffer) ? <div className='ad-buffer'></div> : <></>}
          <div className='splash-wrapper' onClick={() => this.skipSplash()}>
            <SplashScreen play={splash} callback={this.splashCallback.bind(this)} />
          </div>
          <CreateAnimation
            ref={this.UIFadeInRef}
            duration={1000}
            iterations={1}
            fromTo={[
              { property: 'opacity', fromValue: '0', toValue: '1' }
            ]}>
              <div className={(splash && !splashSkipped) ? 'ui-wrapper invisible' : 'ui-wrapper visible'}>
                <SetupUI callback={this.setupCallback.bind(this)} />
              </div>
         </CreateAnimation>
        </>
      );
    }
  }
};

export default withApplicationState(LoopController);
import React from 'react';
import { IonIcon } from '@ionic/react';
import '../theme/BottomBar.css';

import withApplicationState from '../store';
import { ApplicationState } from '../ts/interfaces';

import iconSettings from '../assets/icons/ui/icons_wordigins_settings_white.svg';
import iconSettingsOrange from '../assets/icons/ui/icons_wordigins_settings_orange.svg';
import iconHome from '../assets/icons/ui/icon_home.svg';
import iconInfo from '../assets/icons/ui/icon_info.svg';
import iconInfoOrange from '../assets/icons/ui/icon_info_orange.svg';
import iconAchievements from '../assets/icons/scoreboard/icons_wordigins_scoreboard_white.svg';
import iconAchievementsOrange from '../assets/icons/scoreboard/icons_wordigins_scoreboard_orange.svg';

interface BottomBarProps {
    store:             ApplicationState;
    home:                       boolean;
    info:                       boolean;
    scores:                     boolean;
    settings:                   boolean;
    infoActive?:                boolean;
    settingsActive?:            boolean;
    scoresActive?:              boolean;
    achievementsCallback: () => void;
    settingsCallback: () =>     void;
    infoCallback:     () =>     void;
    homeCallback:     () =>     void;
}

interface BottomBarState { }

class BottomBar extends React.Component<BottomBarProps, BottomBarState> {
    render () {
        const { home, info, scores, settings, infoActive, settingsActive, scoresActive, achievementsCallback, settingsCallback, infoCallback, homeCallback } = this.props;
        const { scoreSubmitted, submissionsOn } = this.props.store;
        return (
            <div className="home?">
                    <div className="bottom-bar">
                        <div></div>
                        { (home) ?
                        <IonIcon icon={iconHome} 
                                 onClick={() => homeCallback()} /> 
                            :
                        <IonIcon icon={iconHome}
                                style={{opacity: 0.2}} />
                            }

                        { (info) ?
                        <IonIcon icon={(infoActive) ? iconInfoOrange : iconInfo} 
                                onClick={() => infoCallback()}/> 
                            :
                        <IonIcon icon={iconInfo} 
                                style={{opacity: 0.2}} />
                            }

                        { ((scores && scoreSubmitted) || (scores && !submissionsOn)) ?
                        <IonIcon icon={(scoresActive) ? iconAchievementsOrange : iconAchievements} 
                                 onClick={() => achievementsCallback()} /> 
                            :
                        <IonIcon icon={iconAchievements}
                                style={{opacity: 0.2}} />
                            }

                        { (settings) ?
                        <IonIcon icon={(settingsActive) ? iconSettingsOrange : iconSettings} 
                                 onClick={() => settingsCallback()} /> 
                            :
                        <IonIcon icon={iconSettings}
                                style={{opacity: 0.2}} />
                            }
                        <div></div>
                    </div>
            </div>
        );
    }
}

export default withApplicationState(BottomBar);
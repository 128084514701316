/** Summary Screen Component */

import React from 'react';
import { IonAlert, IonButton, IonIcon, IonInput } from '@ionic/react';
import '../theme/Summary.css';

import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { Preferences } from '@capacitor/preferences';
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { FileSharer } from '@byteowls/capacitor-filesharer';
import { Howl } from 'howler';

import withApplicationState from '../store';
import { correctResponseActionTypes, 
         countCorrectActionTypes, 
         lastResponseActionTypes, 
         questionIndexActionTypes, 
         questionsArrActionTypes, 
         quizInProgressActionTypes, 
         submittedActionTypes,
         scoreSubmittedActionTypes } from '../ts/actionTypes';
import { ScoreRecord, ApplicationState } from '../ts/interfaces';
import { NUM_QUESTIONS, appVersion, apiURI, disallowedWords, ENVIRONMENT } from '../ts/environment';
import signReq from '../ts/signRequest';
import AdMobFuncs from '../ts/adMob';

import axios from 'axios';
import '@capacitor-community/firebase-analytics';

import iconRandom from '../assets/icons/random/icons_wordigins_random_white.svg';
import iconBrands from  '../assets/icons/brand/icons_wordigins_brand_white.svg';
import iconSports from  '../assets/icons/sports/icons_wordigins_sports_white.svg';
import iconArts   from  '../assets/icons/arts/icons_wordigins_arts_white.svg';
import iconMoney  from '../assets/icons/money/icons_wordigins_money_white.svg';
import iconImitative from '../assets/icons/imitative/icons_wordigins_imitative_white.svg';
import iconFood from '../assets/icons/food/icons_wordigins_food_white.svg';
import iconImported from '../assets/icons/imported/icons_wordigins_import_white.svg';
import iconFacebook from '../assets/icons/share/logo-facebook.svg';
import iconX from '../assets/icons/share/logo-x.svg';
import iconInsta from '../assets/icons/share/logo-instagram.svg';
import iconMail from '../assets/icons/share/mail-open-outline.svg';
import iconShare from '../assets/icons/share/share-social-outline.svg';
import { Buffer } from 'buffer';

import iconReligious from '../assets/icons/religion/icons_wordigins_religion_white.svg'

interface SummaryProps {
  store:             ApplicationState;
  dispatch:          ({ type }: { type: string; payload?: any; }) => void;

  playAgainCallback: (reset: boolean) => void;
  openScores: () => void;
};

interface SummaryState {
  initials:         string;
  lastInitials:     string;
  scores:           Array<ScoreRecord>;
  showAlert:        boolean;
  headerVisibility: object;
  inputMargin:      object;
};

class Summary extends React.Component<SummaryProps, SummaryState> {
  private _isMounted: boolean;

  constructor(props: SummaryProps) {
    super(props);

    this._isMounted = false;

    this.state = {
      initials: '',
      lastInitials: '',
      scores: [],
      headerVisibility:       {},
      inputMargin:            {},
      showAlert: false,
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async componentDidMount () {
    const { currentDifficulty, countCorrect, startTime} = this.props.store;
    this._isMounted = true;

    // Get scoreboard for difficulty
    let key = currentDifficulty + 'Scores';
    Preferences.get({key: key})
      .then(response => {
        if (response.value !== null) {
          let scoreArr = JSON.parse(response.value);
          scoreArr.reverse();
        
          // Don't try to set after component is unmounted
          if (this._isMounted) {
            this.setState({
              scores: scoreArr
            });
          }
        }
      }).catch(e => {
        console.error(e);
      });
    
      //console.log('GAME UUID IN Quiz Completed');
      //console.log(this.props.store.gameUuid);

    // Log quiz_ended event with quiz details and score
    if (ENVIRONMENT === 'production') {
      FirebaseAnalytics.logEvent({
        name: 'quiz_completed',
        params: {
          game_uuid: this.props.store.gameUuid,
          count_correct: countCorrect,
          count_answered: NUM_QUESTIONS,
        }
      });
    }

    // Set default initials as the latest initials entered.
    let lastIns = (await Preferences.get({key: 'lastInitials'})).value;
    if (lastIns) this.setState({lastInitials: lastIns});

    Preferences.set({
      key: 'currentTime',
      value: JSON.stringify(new Date().getTime() - startTime)
    })
  }

  /** 
   * Different comment text depending on score
   */
  getCommentText(percent: number) {
    const { currentDifficulty } = this.props.store;

    let text = '';
    switch (currentDifficulty) {
      case 'easy': {
        if (percent > .9) {
          text = 'VALEDICTORIAN';
        } else if (percent > .8) {
          text = 'SALUTATORIAN';
        } else if (percent > .7) {
          text = 'SUMMA CUM LAUDE';
        } else if (percent > .6) {
          text = 'MAGNA CUM LAUDE';
        } else if (percent > .5) {
          text = 'CUM LAUDE';
        } else if (percent > .4) {
          text = 'BACHELOR\'S DEGREE';
        } else if (percent > .3) {
          text = 'ASSOCIATE\'S DEGREE';
        } else if (percent > .2) {
          text = 'DEAN\'S LIST';
        } else if (percent > .1) {
          text = 'HONORS PROGRAM';
        } else if (percent > 0) {
          text = 'ENGLISH MAJOR';
        } else {
          text = 'STUDENT';
        }
        break;
      }
      case 'moderate': {
        if (percent > .9) {
          text = 'DEAN';
        } else if (percent > .8) {
          text = 'DISTINGUISHED PROFESSOR';
        } else if (percent > .7) {
          text = 'PROFESSOR';
        } else if (percent > .6) {
          text = 'ASSOCIATE PROFESSOR';
        } else if (percent > .5) {
          text = 'ASSISTANT PROFESSOR';
        } else if (percent > .4) {
          text = 'ADJUNCT PROFESSOR';
        } else if (percent > .3) {
          text = 'DOCTORATE';
        } else if (percent > .2) {
          text = 'DOCTORAL CANDIDATE';
        } else if (percent > .1) {
          text = 'LECTURER';
        } else if (percent > 0) {
          text = 'MASTER\'S DEGREE';
        } else {
          text = 'MASTER\'S CANDIDATE';
        }
        break;
      }
      case 'hard': {
        if (percent > .9) {
          text = 'HERMES';
        } else if (percent > .8) {
          text = 'POET LAUREATE';
        } else if (percent > .7) {
          text = 'GURU';
        } else if (percent > .6) {
          text = 'WORD WIZARD';
        } else if (percent > .5) {
          text = 'LINGUIST';
        } else if (percent > .4) {
          text = 'PHILOLOGIST';
        } else if (percent > .3) {
          text = 'LEXICOGRAPHER';
        } else if (percent > .2) {
          text = 'PUBLISHER';
        } else if (percent > .1) {
          text = 'EDITOR';
        } else if (percent > 0) {
          text = 'WRITER';
        } else {
          text = 'SCRIBE';
        }
        break;
      }
      default : text = 'GREAT JOB!'
    }
    return text;
  }

  /**
   * Procedure for ending quiz and returning to setup screen
   * Reset all relevant global vars and resume ad banner
   */
  endQuiz() {
    const { platform, soundsOn, vibrationOn, uiAudio, focused } = this.props.store;

    if (focused && soundsOn) {
      const uiPopAudio: Howl = new Howl({
        src: ['assets/pop.mp3'],
        format: ['mp3'],
        volume: 0.3,
        onloaderror: (id, e) => console.log('failed: ' + id + ' msg: ' + e)
    });
    uiPopAudio.play();
    } else {
      if (soundsOn) uiAudio.play();
    }

    if (platform !== 'web' && vibrationOn) Haptics.impact({style: ImpactStyle.Light});

    this.props.dispatch({
      type: questionsArrActionTypes.SET_QUESTIONS_ARR,
      payload: []
    });

    this.props.dispatch({
      type: questionIndexActionTypes.SET_INDEX,
      payload: undefined
    });

    this.props.dispatch({
      type: quizInProgressActionTypes.SET_NOT_IN_PROGRESS,
    });

    this.props.dispatch({
      type: submittedActionTypes.SET_NOT_SUBMITTED
    });

    this.props.dispatch({
      type: correctResponseActionTypes.SET_INCORRECT
    });

    this.props.dispatch({
      type: lastResponseActionTypes.SET_RESPONSE,
      payload: ''
    });

    this.props.dispatch({
      type: countCorrectActionTypes.SET_COUNT,
      payload: 0
    });

    if (platform !== 'web') AdMobFuncs.resumeBanner();

    try {
      this.clearGame();
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Procedure for starting new quiz with same setup
   * Reset all relevant global vars
   */
  playAgain () {
    const { platform, soundsOn, vibrationOn, uiAudio, focused } = this.props.store;

    if (platform !== 'web') AdMobFuncs.resumeBanner();

    this.props.dispatch({
      type: scoreSubmittedActionTypes.SET_SCORE_SUBMITTED,
      payload: false
    });

    if (focused && soundsOn) {
      const uiPopAudio: Howl = new Howl({
        src: ['assets/pop.mp3'],
        format: ['mp3'],
        volume: 0.3,
        onloaderror: (id, e) => console.log('failed: ' + id + ' msg: ' + e)
    });
    uiPopAudio.play();
    } else {
      if (soundsOn) uiAudio.play();
    }

    if (platform !== 'web' && vibrationOn) Haptics.impact({style: ImpactStyle.Light});

    this.props.playAgainCallback(true);
    try {
      this.clearGame();
    } catch (e) {
      console.error(e);
    }
  }

  async getShareUrl () {
    const { currentDifficulty, countCorrect, currentCategory } = this.props.store;

    var jpegUrl = "https://wordigins.com/media/SocialSharingDev/socialshare_" + countCorrect + "_" + currentCategory + "_" + currentDifficulty + ".png"
    //let jpegUrl = "https://wordigins.com/wp-content/uploads/2022/01/socialshare_0_arts_moderate.jpeg"
    let shareUrl = await this.getBase64(jpegUrl);
    var shareUrlString: string = '';
    if ( typeof shareUrl === "string" ) {
      shareUrlString = shareUrl;
    } else {
      shareUrlString = "error";
    }
    return shareUrlString;
  }

  async getBase64(url: string) {
    try {
      const response = await axios.get(url, {responseType: 'arraybuffer'});
      let stringResponse: string = Buffer.from(response.data, 'binary').toString('base64')
      return stringResponse;
    } catch (error) {
      return error;
    }
  }
    
  async shareAll () {
    FileSharer.share({
      filename: "wordigins_score.png",
      base64Data: await this.getShareUrl(),
      contentType: "image/png",
    }).then(() => {
      //console.log("File shared");
    }).catch(error => {
      console.error("File sharing failed", error.message);
    });
  }

  
  /**
   * Clears stored game when finished
   */

  private clearGame() {
    Preferences.set({
      key: 'savedGame',
      value: '0'
    });
    Preferences.remove({
      key: 'countCorrectKey',
    });
    Preferences.remove({
      key: 'currentQuestionIndexKey',
    });
    Preferences.remove({
      key: 'currentCategoryKey',
    });
    Preferences.remove({
      key: 'currentDifficultyKey',
    });
    Preferences.remove({
      key: 'scoreSubmittedKey',
    });
    Preferences.remove({
      key: 'currentQuestionsArrayKey',
    });
  }

  /**
   * Procedure to submit a score record to the leaderboard DB
   */
  async submitScore() {
    const { initials } = this.state;
    if (disallowedWords.includes(initials.toUpperCase())) {
      this.setShowAlert(true);
    } else {
      const { uuid, currentDifficulty, currentCategory, countCorrect } = this.props.store;

      // Use last initials entered unless they have been changed
      let initials = (this.state.initials !== '') ? this.state.initials : this.state.lastInitials;
      let date = Math.round(+new Date()/1000).toString();
      let time = await Preferences.get({key: 'currentTime'});
      let timeValue = time.value;
      let timeFinal: number = 9999000;
      if (timeValue !== null) {
        timeFinal = Math.round(+timeValue);
      }

      // Submit score record to db
      let scoreRecordBody: ScoreRecord = {
        uuid: uuid,
        initials: initials.toUpperCase(),
        score: countCorrect,
        num_questions: NUM_QUESTIONS,
        date: date,
        category: currentCategory,
        difficulty: currentDifficulty,
        game_time: timeFinal,

        // data required by API
        version: appVersion,
        sig: signReq(uuid, date),
        timestamp: date
      }

      this.props.dispatch({
        type: scoreSubmittedActionTypes.SET_SCORE_SUBMITTED,
        payload: true
      });

      Preferences.set({
        key: 'scoreSubmittedKey',
        value: JSON.stringify(true)
      });
      
      let success = true;
      try {
        let res = await axios.post(apiURI + '/records/register', scoreRecordBody, ENVIRONMENT !== 'production' ? { auth: {username: "igins", password: "llc"}} : {})
        //console.log('Score Record Register Response: Status ' + res.data.status + ', ' + res.data.status_message);
      } catch (e) {
        success = false;
        console.log('Failed to submit score, cacheing for later submission');
        console.error(e);

        // Cache failed submissions for later submission attempt
        let failedSubmissions: Array<ScoreRecord> = [];
        await Preferences.get({key: 'failedSubmissions'})
          .then(response => {
          if (response.value !== null) {
            failedSubmissions = JSON.parse(response.value);
          }
          }).catch(e => {
            console.error(e);
          });

        failedSubmissions.push(scoreRecordBody);
        
        try {
          await Preferences.set({key: 'failedSubmissions', value: JSON.stringify(failedSubmissions)});
        } catch (e) {
          console.error(e);
        }
      }

      // If submission was successful, try to submit past failed scores
      if (success) this.submitCachedFailures();

      // Store latest initials
      if (initials !== '') {
        Preferences.set({
          key: 'lastInitials',
          value: initials.toUpperCase()
        });
      }
    }
  }

  /**
   * Procedure to submit old scores that were cached upon failed submission
   */
  async submitCachedFailures () {

    // Check for cached scores
    let failedSubmissions: Array<ScoreRecord> = [];
    await Preferences.get({key: 'failedSubmissions'})
      .then(response => {
        if (response.value !== null) {
          failedSubmissions = JSON.parse(response.value);
        }
      }).catch(e => {
        console.error(e);
      });

    if (failedSubmissions.length > 0) {
      //console.log('Found cached failures, trying to submit');

      let newFailedSubmissions: Array<ScoreRecord> = [];
      failedSubmissions.forEach(async (failedSubmission) => {
        let timestamp = Math.round(+new Date()/1000).toString();

        // Re-sign bundle with fresh timestamp
        failedSubmission.timestamp = timestamp;
        failedSubmission.sig = signReq(failedSubmission.uuid, timestamp);

        // Try to submit cached score
        try {
          let res = await axios.post(apiURI + '/records/register', failedSubmission, ENVIRONMENT !== 'production' ? { auth: {username: "igins", password: "llc"}} : {})
          //console.log('Score Record Register Response: Status ' + res.data.status + ', ' + res.data.status_message);
        } catch (e) {
          console.error(e);
          newFailedSubmissions.push(failedSubmission);
        }
      });

      // Return any new failures to cache, or empty failure cache
      try {
        await Preferences.set({key: 'failedSubmissions', value: JSON.stringify(newFailedSubmissions)});
      } catch (e) {
        console.error(e);
      }
    }
  }

  setInitials (text: string) {
      this.setState({initials: text});
  }

  setShowAlert (bool: boolean) {
    this.setState({
      showAlert: bool
    });
  }

  onKeyUp(event: any) {
    // Prevent non-alphabetic characters
    let newValue = event.target.value;
    let regExp = new RegExp('^[A-Za-z?]+$');

    while (! regExp.test(newValue) && newValue.length > 0) {
      newValue = newValue.slice(0, -1);
    }
    event.target.value = newValue;
  }

  render() {
    const { currentQuestionsArray, countCorrect, submissionsOn, device, scoreSubmitted } = this.props.store;
    const { initials, scores, showAlert, headerVisibility, inputMargin  } = this.state;
    const numQuestions = currentQuestionsArray.length;

    let commentText = this.getCommentText(countCorrect / numQuestions);
    let commentTextLength = 'short';
    if (commentText.length > 7) commentTextLength = 'med';
    if (commentText.length > 15) commentTextLength = 'long';

    // Build scoreboard
    let scoreboard: Array<JSX.Element> = [];
    for ( let i = 0; i < 3; i++) {
      let scoreRecord = scores[i];
      if (!scoreRecord) break;

      // Set category icon to be used
      let categoryIcon = iconRandom;
      switch (scoreRecord.category) {
        case 'brands':
            categoryIcon = iconBrands;
            break;
          case 'sports':
            categoryIcon = iconSports;
            break;
          case 'arts':
            categoryIcon = iconArts;
            break;
          case 'religious':
            categoryIcon = iconReligious;
            break;
          case 'money':
            categoryIcon = iconMoney;
            break;
          case 'imitative':
            categoryIcon = iconImitative;
            break;
          case 'food':
            categoryIcon = iconFood;
            break;
          case 'imported':
            categoryIcon = iconImported;
            break;
          default:
            categoryIcon = iconRandom;
      }

      let date = new Date(scoreRecord.date);
      let classes = (i === 0) ? 'summary-score-record latest-record' : 'summary-score-record';
      scoreboard.push(
        <tr className={classes} key={i}>
          <td>{scoreRecord.score}</td>
          <td>{date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate()}</td>
          <td>{scoreRecord.difficulty}</td>
          <td><IonIcon icon={categoryIcon} /></td>
        </tr>
      );
    }

    let submitButton = <IonButton className='score-submit-button' disabled>SUBMIT</IonButton>
    if (!scoreSubmitted) {
      submitButton = <IonButton className='score-submit-button' disabled={false} onClick={() =>this.submitScore()}>SUBMIT</IonButton>
    }

    let playAgainButton = <IonButton disabled>PLAY AGAIN</IonButton>
    if (scoreSubmitted || !submissionsOn) {
      playAgainButton = <IonButton disabled={false} onClick={() => this.playAgain()}>PLAY AGAIN</IonButton>
    }

    let endQuizButton = <IonButton disabled>END GAME</IonButton>
    if (scoreSubmitted || !submissionsOn) {
      endQuizButton = <IonButton disabled={false} onClick={() => this.endQuiz()}>END GAME</IonButton>
    }

    return (
      <>
      <div className="summary-wrapper">
          <div style={headerVisibility} className="summary-header">
            <h2>YOUR SCORE</h2>
            <h1>{countCorrect/numQuestions*100}%</h1>
            <h3 className={commentTextLength}>{commentText}</h3>
          </div>
          <div className="summary-scores-wrapper">
            { (submissionsOn && !scoreSubmitted) ?
                <>
                  <h4>Enter Your Initials</h4>
                  <div className='score-submit-input'>
                    <IonInput style={inputMargin} value={initials} maxlength={3} placeholder={this.state.lastInitials} onKeyUp={e => this.onKeyUp(e)} onIonInput={e => this.setInitials( e.detail.value || '' )} />
                    {submitButton}
                  </div>
                </>
              :
                <></>
            }
            <table>
              <thead>
                <tr className="summary-score-record list-headers"><th>SCORE</th><th>DATE</th><th>DIFFICULTY</th><th>CAT.</th></tr>
              </thead>
              <tbody>
                {scoreboard}
              </tbody>
            </table>
          </div>
        <div className="share-text">
          <p>SHARE YOUR ACCOMPLISHMENT!</p>
        </div>
          <div className="share-button">
            <IonButton expand="block" onClick={() => this.shareAll()}>
              SHARE
              <IonIcon slot="end" icon={iconFacebook} />
              <IonIcon slot="end" icon={iconX} />
              <IonIcon slot="end" icon={iconInsta} />
              <IonIcon slot="end" icon={iconMail} />
              <IonIcon slot="end" icon={iconShare} />
            </IonButton>
          </div>
          <div className="bottom-buttons">
            {playAgainButton}
            {endQuizButton}
          </div>
        </div>
        <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => this.setShowAlert(false)}
            cssClass="initials-alert"
            header={'Invalid initials'}
            message={'Sorry, we\'ve detected that your initials could be potentially offensive.<br><br>Please choose another set of initials!'}
            buttons={['Okay']}
          />
      </>
    )
  }
};

export default withApplicationState(Summary);
import crypto from 'crypto';
import { apiSec } from './environment';


/**
 * Function to generate a unique and verifiable signature for any request body
 * @param req request body to sign
 */
export default function signRequest (uuid: string, date: string) {
    // Create URI string combining signature data
    let sigData = 'uuid=' + uuid + '&date=' + date;

    // Encrypt using SHA-1 and shared secret
    let sig = crypto.createHmac('sha256', apiSec)
                .update(sigData)
                .digest('hex');

    return sig;
}
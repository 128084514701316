import React from 'react';
import { CreateAnimation, IonIcon } from '@ionic/react';
import '../theme/SplashScreen.css';

import word_origins from '../assets/app_logo/word_origins.svg';
import word_origins_cursor from '../assets/app_logo/word_origins_cursor.svg';
import word_oigins_cursor from '../assets/app_logo/word_oigins_cursor.svg';
import word_igins_cursor from '../assets/app_logo/word_igins_cursor.svg';
import wordigins_cursor from '../assets/app_logo/wordigins_cursor.svg';
import wordigins from '../assets/app_logo/wordigins.svg';
import tagline from '../assets/app_logo/mind.svg';


interface SplashScreenProps {
    play: boolean;
    callback: () => void;
};
interface SplashScreenState {
    icon: string;
};

function delay (ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
}

class SplashScreen extends React.Component<SplashScreenProps, SplashScreenState> {
    private moveAllUpRef: React.RefObject<CreateAnimation> = React.createRef();
    private taglineRevealRef: React.RefObject<CreateAnimation> = React.createRef();

    constructor(props: SplashScreenProps) {
        super(props);
        this.state = {
            icon: (props.play) ? word_origins : wordigins,
        }
    }

    async componentDidMount() {
        const { play, callback } = this.props;

        if (play) {
            const moveAllUp = this.moveAllUpRef.current!.animation;
            const taglineReveal = this.taglineRevealRef.current!.animation;

            await delay(2000);
            this.setState({
                icon: word_origins_cursor,
            });
            await delay(500);
            this.setState({
                icon: word_oigins_cursor,
            });
            await delay(500);
            this.setState({
                icon: word_igins_cursor,
            });
            await delay(500);
            this.setState({
                icon: wordigins_cursor,
            });
            await delay(500);
            this.setState({
                icon: wordigins,
            });
            await taglineReveal.play();
            await moveAllUp.play();
            callback();
        }
    }

    render() {
        const { play } = this.props;
        const { icon } = this.state;

        return(
            <>
            { (play) ? 
                <CreateAnimation
                    ref={this.moveAllUpRef}
                    duration={1000}
                    easing={'ease-in-out'}
                    iterations={1}
                    fromTo={[
                        { property: 'transform', fromValue: 'translateY(30vh)', toValue: 'translateY(0px)' }
                    ]}>
                    <div className="logo-tagline-container">
                        <div className="logo-container">
                            <IonIcon className="word-origins-base" icon={icon} />
                        </div>
                        <CreateAnimation
                            ref={this.taglineRevealRef}
                            duration={1000}
                            delay={2000}
                            iterations={1}
                            fromTo={[
                                { property: 'opacity', fromValue: '0', toValue: '1' }
                            ]}>
                                <IonIcon className="tagline-svg" icon={tagline} />
                        </CreateAnimation>
                    </div>
                </CreateAnimation>
                :
                <div className="static-logo-container">
                    <div className="logo-container">
                        <IonIcon className="word-origins-base" icon={icon} />
                    </div>
                    <IonIcon className="static-tagline-svg" icon={tagline} />
                </div>
            }
            </>
        );
    }
}

export default SplashScreen;
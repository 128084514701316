import axios, { all } from 'axios';
import { QuestionData, WordListRequestBody, WordJSON, WordData } from './interfaces';
import { apiURI, CATEGORIES, SUBCATEGORIES, QUESTION_TYPES, DEBUG_TYPES, ORIGIN_LANGUAGES, COMMON_ORIGIN_LANGUAGES, NUM_OPTIONS, COMMON_ORIGIN_LINIAGES, ARTICLES, DEBUG, ENVIRONMENT} from './environment';

export {
    getQuestionsArr
};

/**
 *  Default values for minimum date, and maximum date range for date generation
 */
const DEFAULT_DATE_RANGE = 500;
const DEFAULT_DATE_MIN = 1500;
var extraCount = 0;

/**
 * Helper function to shuffle an array in place
 * @param arr array to process
 */
function wordigins_shuffleArr(arr: Array<any>) {
    for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[j]] = [arr[j], arr[i]];
    }
}

/**
 * Ensures that: 
 *  Two of the same question type don't appear in a row
 *  There is a maximum of two category-related functions
 *  Each subject word only appears once
 *  Limits date-range-include, date-range-exclude, date, order, first, and last questions to one per game in easy mode
 * @param arr array to process
 */
async function wordigins_question_filter(arr: Array<any>, diff: string) {
    let newArr: Array<QuestionData> = [];
    let shuffles = 0;
    let cat_qs = 0;
    let first_last_count = 0;
    let easyDateCount = 0;

    if (arr.length > 0) {
        newArr[0] = arr.shift();

        // Increment counters if the first question is of a specific type
        if (newArr[0].type === "category-exclude" || newArr[0].type === "category-include") {
            cat_qs++;
        }
        if (newArr[0].type === "first" || newArr[0].type === "last" ) {
            first_last_count++;
        }
        if (["date-range-include", "date-range-exclude", "date", "order", "first", "last"].includes(newArr[0].type) && diff === "easy") {
            easyDateCount++;
        }
    }

    while (arr.length > 0) {
        let unique_word = true;
        for (let i = 0; i < newArr.length; i++) {
            if (arr[0].subject === newArr[i].subject) {
                unique_word = false;
            }
        }

        // If unable to shuffle, return shorter array
        if (shuffles === 10) {
            break;
        }

        // Add question if all conditions are met
        if (
            (newArr[newArr.length - 1].type !== arr[0].type) &&
            ((cat_qs < 2) || ((arr[0].type !== "category-exclude") && (arr[0].type !== "category-include"))) &&
            ((first_last_count < 1) || ((arr[0].type !== "first") && (arr[0].type !== "last"))) &&
            (unique_word) &&
            ((easyDateCount < 1) || (!["date-range-include", "date-range-exclude", "date", "order", "first", "last"].includes(arr[0].type) || diff !== "easy"))
            
        ) {
            newArr[newArr.length] = arr.shift();
            shuffles = 0;

            // Increment counters if the added question is of a specific type
            if (newArr[newArr.length - 1].type === "category-exclude" || newArr[newArr.length - 1].type === "category-include") {
                cat_qs++;
            }
            if (newArr[newArr.length - 1].type === "first" || newArr[newArr.length - 1].type === "last") {
                first_last_count++;
            }
            if (["date-range-include", "date-range-exclude", "date", "order", "first", "last"].includes(newArr[newArr.length - 1].type) && diff === "easy") {
                easyDateCount++;
            }
        } else {
            wordigins_shuffleArr(arr);
            shuffles++;
        }
    }
    return newArr;
}

/**
 * Helper function to return n random unique values from an array
 * from https://stackoverflow.com/questions/19269545/how-to-get-a-number-of-random-elements-from-an-array
 * @param arr array to process
 * @param n   number of unique random elements to get, default 1
 * 
 * @return {Array} array of length n random elements from arr
 */
function wordigins_chooseRand(arr: Array<any>, n: number = 1): Array<any> {
    var result = new Array(n),
        len = arr.length,
        taken = new Array(len);
    if (n > len)
        // Silently return empty when overdrawn
        return [];
    while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
}

async function wordigins_extra_questions(questionsArr: Array<QuestionData>, n: number, diff: string, uuid: string, cat: string, exclude_types?: Array<string>) {
    // If array doesn't have enough questions (due to bad request, etc.), build more until it does
    let validQuestionTypes = [...QUESTION_TYPES];
    while (questionsArr.length < n) {
        if (extraCount < 15) {
            extraCount = extraCount + 1;
            questionsArr.forEach(question => {
                if (question.type === "category-include" || question.type === "category-exclude" || question.type === "date-range-exclude" || question.type === "date-range-include" || question.type === "first" || question.type === "last" || question.type === "date") {
                    let index = validQuestionTypes.indexOf(question.type);
                    if (index > -1) {
                        validQuestionTypes.splice(index, 1);
                    }
                }
            });
            let type = wordigins_chooseRand(validQuestionTypes)[0];

            // Exclude date range questions from easy difficulty
            if ((type === "date-range-include" || type === "date-range-exclude") && diff === "easy") {
                continue;
            }

            // Exclude order questions from easy difficulty
            if (type === "order" && diff === "easy") {
                continue;
            }

            if ((type !== "category-include" && type !== "category-exclude") || cat !== "brands") {
                // guard to prevent crash of debug mode
                if ((type !== 'order' && type !== 'first' && type !== 'last') || (DEBUG === false)) {
                    try {
                        let extraQuestionSet: Array<QuestionData> = await getQuestion(uuid, type, diff, diff, cat);
                        if (extraQuestionSet[0] !== undefined) {
                            questionsArr.push(extraQuestionSet[0]);
                        }
                    } catch (e) {
                        console.error(e);
                    }
                }
            }
        } else {
            break;
        }
    }
}

/**
 * Builds a set of questions
 * @param n     number of questions to build
 * @param diff  overall difficulty
 * @param cat   category
 * 
 * @return {promise} promise for an array of QuestionJSON according to the params
 */
async function getQuestionsArr(uuid: string, n: number, diff: string, cat: string, debugWords: Array<string>): Promise<Array<QuestionData>> {

    // Default max number of each question type = (number of questions requested) / (total num question types) rounded up
    // Shuffle types so we don't get the same weights every time
    let shuffledTypes = [...QUESTION_TYPES],
        shuffledDebugTypes = [...DEBUG_TYPES];
    wordigins_shuffleArr(shuffledTypes);
    wordigins_shuffleArr(shuffledDebugTypes);

    // console.log(shuffledTypes);

    // Get questions in parallel
    let questionPromises: Array<Promise<Array<QuestionData>>> = [];
    for (let i = 0; i < (n); i++) {
        if (questionPromises.length < n) {

            let type = shuffledTypes[i],
                debugWord = undefined;
            if (debugWords.length > 0) {
                type = shuffledDebugTypes[i % 8];
                debugWord = debugWords.shift();
            }

            // //skip if easy diff and type is date-range
            if ((type === "date-range-include" || type === "date-range-exclude") && diff === "easy") {
                continue;
            }

            // Exclude order questions from easy difficulty
            if (type === "order" && diff === "easy") {
                continue;
            }

            if ((type !== "category-include" && type !== "category-exclude") || cat !== "brands") {
                // Prevent debug crash??
                if (!DEBUG || (type !== 'order' && type !== 'first' && type !== 'last')) {
                    questionPromises.push(getQuestion(uuid, type, diff, diff, cat, debugWord));
                }
            }
        }
    }

    let questionsArr: Array<QuestionData> = [];

    // Restart logic if stuck for 20 seconds
    setTimeout(function () {
        if (questionsArr.length !== n) {
            getQuestionsArr(uuid, n, diff, cat, debugWords);
        }
    }, 20000);

    // Resolve when all requests are settled (use allSettled() when capacitor supports that for android build)
    await Promise.all(questionPromises).then(async (questionSets) => {
        // Unpack all question sets into array
        questionSets.forEach(questionSet => {
            questionSet.forEach(question => {
                questionsArr.push(question);
            });
        });

        // Change word difficulty when deficits are encountered, no change in debug mode
        // let deficitDiff = (diff === 'moderate') ? 'easy' : 'moderate';

        await wordigins_extra_questions(questionsArr, n, diff, uuid, cat);
        wordigins_shuffleArr(questionsArr);

        // Ensure two questions of the same type don't appear in a row
        questionsArr = await wordigins_question_filter(questionsArr, diff);
        while (questionsArr.length < n) {
            if (extraCount < 15) {
                await wordigins_extra_questions(questionsArr, n, diff, uuid, cat);
                questionsArr = await wordigins_question_filter(questionsArr, diff);
            } else {
                extraCount = 0;
                break;
            }
        }
    });
    extraCount = 0;
    return questionsArr;
}

/**
 * Gets a number of questions with all attributes defined
 * @param type question type
 * @param qDiff question difficulty
 * @param wDiff word difficulty
 * @param cat category
 * @param debugWord word to guarantee as subject or correct answer
 * @param n number of questions requested with these attributes
 */
function getQuestion(uuid: string, type: string, qDiff: string, wDiff: string | undefined, cat: string | undefined, debugWord?: string): Promise<Array<QuestionData>> {
    cat = (cat === 'random') ? undefined : cat;

    if (qDiff === 'easy') {
        var numOptions = NUM_OPTIONS - 1;
    } else if (qDiff === 'moderate') {
        var numOptions = NUM_OPTIONS;
    } else {
        var numOptions = NUM_OPTIONS + 1;
    }

    let getQuestionPromise: Promise<Array<QuestionData>>;
    switch (type) {
        case 'date':
            getQuestionPromise = buildDateQuestions(uuid, qDiff, cat ? false : true, wDiff, cat ? cat : wordigins_chooseRand(CATEGORIES, 1)[0], debugWord, numOptions);
            break;
        case 'date-range-include':
            getQuestionPromise = buildDateRangeQuestions(uuid, qDiff, cat ? false : true, 'include', wDiff, cat ? cat : wordigins_chooseRand(CATEGORIES, 1)[0], debugWord, numOptions);
            break;
        case 'date-range-exclude':
            getQuestionPromise = buildDateRangeQuestions(uuid, qDiff, cat ? false : true, 'exclude', wDiff, cat ? cat : wordigins_chooseRand(CATEGORIES, 1)[0], debugWord, numOptions);
            break;
        case 'order':
            getQuestionPromise = buildOrderQuestions(uuid, qDiff, cat ? false : true, wDiff, cat ? cat : wordigins_chooseRand(CATEGORIES, 1)[0], debugWord, numOptions);
            break;
        case 'category-include':
            getQuestionPromise = buildCategoryQuestions(uuid, cat ? false : true, 'include', wDiff, cat ? cat : wordigins_chooseRand(CATEGORIES, 1)[0], numOptions);
            break;
        case 'category-exclude':
            getQuestionPromise = buildCategoryQuestions(uuid, cat ? false : true, 'exclude', wDiff, cat ? cat : wordigins_chooseRand(CATEGORIES, 1)[0], numOptions);
            break;
        case 'first':
            getQuestionPromise = buildFirstLastQuestions(uuid, qDiff, cat ? false : true, 'first', wDiff, cat ? cat : wordigins_chooseRand(CATEGORIES, 1)[0], numOptions);
            break;
        case 'last':
            getQuestionPromise = buildFirstLastQuestions(uuid, qDiff, cat ? false : true, 'last', wDiff, cat ? cat : wordigins_chooseRand(CATEGORIES, 1)[0], numOptions);
            break;
        case 'origin-include':
            getQuestionPromise = buildOriginQuestions(uuid, qDiff, 'include', wDiff, cat ? cat : wordigins_chooseRand(CATEGORIES, 1)[0], debugWord, numOptions);
            break;
        case 'origin-exclude':
            getQuestionPromise = buildOriginQuestions(uuid, qDiff, 'exclude', wDiff, cat ? cat : wordigins_chooseRand(CATEGORIES, 1)[0], debugWord, numOptions);
            break;
        case 'origin-multi':
            getQuestionPromise = buildOriginMultiQuestions(uuid, qDiff, cat ? false : true, wDiff, cat ? cat : wordigins_chooseRand(CATEGORIES, 1)[0], numOptions);
            break;
        case 'subcat-include':
            getQuestionPromise = buildSubcatQuestions(uuid, qDiff, cat ? false : true, 'include', wDiff, cat ? cat : wordigins_chooseRand(CATEGORIES, 1)[0], debugWord, numOptions);
            break;
        case 'subcat-exclude':
            getQuestionPromise = buildSubcatQuestions(uuid, qDiff, cat ? false : true, 'exclude', wDiff, cat ? cat : wordigins_chooseRand(CATEGORIES, 1)[0], debugWord, numOptions);
            break;
        case 'subcat-origin':
            getQuestionPromise = buildSubcatOriginQuestions(uuid, qDiff, cat ? false : true, wDiff, cat ? cat : wordigins_chooseRand(CATEGORIES, 1)[0], debugWord, numOptions);
            break;
        default:
            getQuestionPromise = Promise.reject(new Error('Invalid question type: ' + type));
    }

    return getQuestionPromise;
}

/**
 *  Returns Date questions
 * 
 *  @param  {string}    questionDifficulty  Determines difficulty of option format
 *  @param  {string}    wordDifficulty      Optional API query for word difficulty filter
 *  @param  {string}    category            Optional API query for word category filter
 *  @param  {string}    debugWord           Word to be guaranteed as subject
 *  @return {promise}   promise             Resolves with question object or array of questions, Rejects with any error       
*/
//no check
async function buildDateQuestions(uuid: string, questionDifficulty: string, random_cat: boolean, wordDifficulty?: string, category?: string, debugWord?: string, numOptions: number = 1): Promise<Array<QuestionData>> {

    // Mod year by number according to difficulty, default easy (range of 50 years)
    let mod: number;
    switch (questionDifficulty) {
        case 'moderate':
            mod = 25;
            break;
        case 'hard':
            mod = 10;
            break;
        default:
            mod = 50;
    }

    // Body of API call
    let body: WordListRequestBody = {
        debug: DEBUG,
        uuid: uuid,
        number: 1,
        random_cat: random_cat,
    }

    // Apply word difficulty filter if present
    if (wordDifficulty !== undefined) body.difficulty = wordDifficulty;

    // Apply word category filter if present
    if (category !== undefined) body.category = category;

    // Apply debug word filter if present
    if (debugWord !== undefined) {
        body.debug_word = debugWord;
    }
    // Format strings according to difficulty
    function formatOptionString(date: string) {
        let result = date;

        if (mod === 25) {
            result += '-' + (parseInt(result) + 24);
        } else if (mod === 50) {
            result += '-' + (parseInt(result) + 49);
        } else {
            result += 's';
        }

        return result;
    }

    // Range and minimum for wrong dates, must not be present in arr
    function getRandomRoundedDate(arr: Array<string>, range = DEFAULT_DATE_RANGE, min = DEFAULT_DATE_MIN) {
        let result = Math.floor(Math.random() * (range / mod)) * mod + min;

        while (arr.includes(formatOptionString('' + result))) {
            result = Math.floor(Math.random() * (range / mod)) * mod + min;
        }

        return result;
    }

    let words: Array<WordJSON> = [];
    try {
        let response = await axios.post(apiURI + '/wordsupdated/list2', body, ENVIRONMENT !== 'production' ? { auth: { username: "igins", password: "llc" } } : {});
        words = response.data.data;
    } catch (e) {
        throw (e);
    }


    let questionsArr: Array<QuestionData> = [];
    for (let wordID in words) {
        let options: Array<string> = [];
        let answerDate = parseInt(words[wordID].researched_date);
        answerDate -= answerDate % mod;
        let answerDateString = formatOptionString('' + answerDate);
        options.push(answerDateString);

        let reviewText = words[wordID].sentence;
        let imageURL = wordigins_chooseRand(words[wordID].images)[0];

        // Generate random date/range and insert if not already present
        while (options.length < numOptions - 1) {
            let option = formatOptionString('' + getRandomRoundedDate(options));
            options.push(option);
        }

        // Shuffle
        wordigins_shuffleArr(options);

        let question: QuestionData = {
            type: 'date',
            reviewText: reviewText,
            reviewImgURL: imageURL,
            category: category,
            subject: words[wordID].word,
            subjectDiff: words[wordID].difficulty,
            options: options,
            answer: answerDateString,
            audioURL: words[wordID].audio
        };
        question.options = question.options.filter((option) => {
            return option !== undefined;
        });
        if (question.options.length === numOptions - 1) {
            questionsArr.push(question);
            if (debugWord) {
                console.log("Built Question for ", debugWord, " Question type: ", "date");
            }
        } else {
            if (debugWord) {
                console.log("Failed to build Question for ", debugWord);
            }
        }
    }

    return questionsArr;
}

/**
 *  Returns Order questions
 * 
 *  @param  {string}    questionDifficulty  Determines how many options to get
 *  @param  {string}    wordDifficulty      Optional API query for word difficulty filter
 *  @param  {string}    category            Optional API query for word category filter
 *  @param  {string}    debugWord           Word to be guaranteed as highlight word
 *  @return {promise}   promise             Resolves with question object or array of questions, Rejects with any error       
 */
async function buildOrderQuestions(uuid: string, questionDifficulty: string, random_cat: boolean, wordDifficulty?: string, category?: string, debugWord?: string, numOptions: number = 1): Promise<Array<QuestionData>> {

    // Body of API call
    let body: WordListRequestBody = {
        debug: DEBUG,
        uuid: uuid,
        number: numOptions + 3,
        random_cat: random_cat,
        check_similar: true,
    }

    // Apply word difficulty filter if present
    if (wordDifficulty !== undefined) body.difficulty = wordDifficulty;

    // Apply word category filter if present
    if (category !== undefined) body.category = category;

    // Apply debug word filter if present
    if (debugWord !== undefined) body.debug_word = debugWord;

    let words: Array<WordData> = [];

    try {
        let response = await axios.post(apiURI + '/wordsupdated/list2', body, ENVIRONMENT !== 'production' ? { auth: { username: "igins", password: "llc" } } : {});

        let wordData: Array<WordJSON> = response.data.data;
        for (const wordID in wordData) {
            words.push({
                word: wordData[wordID].word,
                difficulty: wordData[wordID].difficulty,
                date: wordData[wordID].researched_date,
                sentence: wordData[wordID].sentence,
                images: wordData[wordID].images,
                audio: wordData[wordID].audio
            });
        }
    } catch (e) {
        throw (e);
    }

    let questionsArr: Array<QuestionData> = [];
    for (let i = 0; i <= (words.length - numOptions); i += numOptions) {
        let optionsDates: { [key: string]: string } = {};
        // Get the correct number of options
        let options: Array<WordData> = [];
        let optionStrings: Array<string> = [];
        for (let j = 0; j < numOptions - 1; j++) {
            options.push(words[i + j]);
            optionsDates[words[i + j].word] = words[i + j].date;
            optionStrings.push(words[i + j].word);
        }

        // Create an ordered array from clone of the original
        let optionsOrdered = Array.from(options);
        optionsOrdered.sort((a, b) => (a.date > b.date) ? 1 : -1);

        //sort options alphabetically
        optionStrings.sort();

        let validQuestion = true;

        for (let i = 0; i < optionsOrdered.length - 1; ++i) {
            if (optionsOrdered[i].date === optionsOrdered[i + 1].date) {
                validQuestion = false;
            };
        }

        // Create an answer key of the options order
        let answerOrder: Array<string> = [];
        optionsOrdered.forEach(option => {
            answerOrder.push(option.word);
        });

        // Choose one option for review text/image, use debug word instead if present
        let reviewWord = optionsOrdered[0];
        if (debugWord !== undefined) {
            let debugIx = optionsOrdered.map(e => { return e.word }).indexOf(debugWord);
            reviewWord = optionsOrdered[debugIx];
        }
        if (reviewWord === undefined) {
            reviewWord = optionsOrdered[0];
        }

        let imageURL = wordigins_chooseRand(reviewWord.images)[0];

        let question: QuestionData = {
            type: 'order',
            reviewText: reviewWord.sentence,
            reviewImgURL: imageURL,
            subject: reviewWord.word,
            subjectDiff: reviewWord.difficulty,
            category: category,
            options: optionStrings,
            answer: answerOrder,
            optionsDates: optionsDates,
            audioURL: reviewWord.audio
        }
        question.options = question.options.filter((option) => {
            return option !== undefined;
        });

        if (validQuestion) {
            if (question.options.length === numOptions - 1) {
                questionsArr.push(question);
                if (debugWord) {
                    console.log("Built Question for ", debugWord, " Question type: ", "order");
                }
            } else {
                if (debugWord) {
                    console.log("Failed to build Question for ", debugWord);
                }
            }
        }
    }

    return questionsArr;
}

/**
 *  Returns Category questions
 * 
 *  @param  {string}    includeOrExclude    Value 'include' or 'exclude' can be passed to force a type, default false causes random choice
 *  @param  {string}    wordDifficulty      Optional API query for word difficulty filter
 *  @param  {string}    category            Optional API query to force category filter, default false causes random choice
 *  @return {promise}   promise             Resolves with question object or array of questions, Rejects with any error       
*/
//check on incorrect body, need to send correct word also
async function buildCategoryQuestions(uuid: string, random_cat: boolean, includeOrExclude?: string, wordDifficulty?: string, category?: string, numOptions: number = 1): Promise<Array<QuestionData>> {
    // Set type, random if not passed
    let randType = (Math.floor(Math.random() * 2)) ? 'include' : 'exclude';
    let type = (includeOrExclude) ? includeOrExclude : randType;

    // Pick random category from those available
    let randCats: Array<string> = [
        'sports',
        'money',
        'arts',
        'imitative',
        'food',
        'imported',
    ];
    let cat = (category !== "brands") ? category : wordigins_chooseRand(randCats)[0];
    let correctBody: WordListRequestBody, incorrectBody: WordListRequestBody;
    if (type === 'include') {
        // API call bodies for odd one out in range
        correctBody = {
            debug: DEBUG,
            uuid: uuid,
            number: 1,
            category: cat,
            random_cat: random_cat,
            category_q: true
        }
        incorrectBody = {
            debug: false,
            uuid: uuid,
            number: numOptions + 2,
            exclude_category: cat,
            random_cat: random_cat,
            category_q: true,
            check_similar: true
        }
        if (cat === "money") {
            incorrectBody.exclude_category_2 = "brands";
        } else if (cat === "brands") {
            incorrectBody.exclude_category_2 = "money";
        }
    } else if (type === 'exclude') {
        // API call bodies for odd one out not in range
        incorrectBody = {
            debug: false,
            uuid: uuid,
            number: numOptions + 2,
            category: cat,
            random_cat: random_cat,
            category_q: true,
            check_similar: true
        }
        correctBody = {
            debug: DEBUG,
            uuid: uuid,
            number: 1,
            exclude_category: cat,
            random_cat: random_cat,
            category_q: true
        }
        if (cat === "money") {
            correctBody.exclude_category_2 = "brands";
        } else if (cat === "brands") {
            correctBody.exclude_category_2 = "money";
        }
    } else {
        return Promise.reject(new Error('Invalid question type: ' + type));
    }

    // Apply word difficulty filter if present
    if (wordDifficulty) {
        correctBody.difficulty = wordDifficulty;
        incorrectBody.difficulty = wordDifficulty;
    }

    let correctOptionsArr: Array<string> = [],
        imageURLArr: Array<string> = [],
        reviewTextArr: Array<string> = [],
        audioArr: Array<string> = [],
        subjectDiffArr: Array<string> = [];

    try {
        // Request for correct answers
        var correct_response = await axios.post(apiURI + '/wordsupdated/list2', correctBody, ENVIRONMENT !== 'production' ? { auth: { username: "igins", password: "llc" } } : {});

        var correct = "";
        let correct_wordData: Array<WordJSON> = correct_response.data.data;
        for (let wordID in correct_wordData) {
            correctOptionsArr.push(correct_wordData[wordID].word);
            imageURLArr.push(wordigins_chooseRand(correct_wordData[wordID].images)[0]);
            reviewTextArr.push(correct_wordData[wordID].sentence);
            audioArr.push(correct_wordData[wordID].audio);
            subjectDiffArr.push(correct_wordData[wordID].difficulty);
            correct = correct_wordData[wordID].word;
        }
        incorrectBody.correct = correct;
    } catch (e) {
        throw (e);
    }

    let incorrectOptionsArr: Array<string> = [];

    try {
        // Request for incorrect answers
        var incorrect_response = await axios.post(apiURI + '/wordsupdated/list2', incorrectBody, ENVIRONMENT !== 'production' ? { auth: { username: "igins", password: "llc" } } : {});

        let incorrect_wordData: Array<WordJSON> = incorrect_response.data.data;
        for (let wordID in incorrect_wordData) {
            incorrectOptionsArr.push(incorrect_wordData[wordID].word);
        }
    } catch (e) {
        throw (e)
    }

    let questionsArr: Array<QuestionData> = [];
    try {
        await Promise.all([correct_response, incorrect_response]).then(function () {

            correctOptionsArr.forEach(correct => {
                let options: Array<string | undefined> = [];
                options.push(correct);

                while (options.length < numOptions) {
                    options.push(incorrectOptionsArr.pop());
                }

                // sort options alphabetically without articles
                options.sort(function (a, b) {
                    if (a !== undefined && b !== undefined) {
                        for (var i = 0; i < ARTICLES.length; i++) {
                            a = a.replace(ARTICLES[i] + " ", "");
                            b = b.replace(ARTICLES[i] + " ", "");
                        }

                        return (a > b) ? 1 : -1;
                    }

                    return 0;
                });

                let question: QuestionData = {
                    type: 'category-' + type,
                    reviewText: reviewTextArr.shift(),
                    reviewImgURL: imageURLArr.shift(),
                    subject: correct,
                    subjectDiff: subjectDiffArr.shift()!,
                    category: cat,
                    options: options,
                    answer: correct,
                    audioURL: audioArr.shift(),
                };
                question.options = question.options.filter((option) => {
                    return option !== undefined;
                });
                if (question.options.length === numOptions) questionsArr.push(question);
            });
        });
    } catch (e) {
        throw (e);
    }

    return questionsArr;
}

/**
  *  Returns First/Last questions
  * 
  *  @param  {string}    firstOrLast     Value 'first' or 'last' can be passed to force a type, default false causes random choice
  *  @param  {string}    wordDifficulty  Optional API query for word difficulty filter
  *  @param  {string}    category        Optional API query for word category filter
  *  @return {promise}   promise         Resolves with array of questions, Rejects with any error       
 */
async function buildFirstLastQuestions(uuid: string, questionDifficulty: string, random_cat: boolean, firstOrLast?: string, wordDifficulty?: string, category?: string, numOptions: number = 1): Promise<Array<QuestionData>> {

    // Set type, random if not passed
    let randType = (Math.floor(Math.random() * 2)) ? 'first' : 'last';
    let type = (firstOrLast !== undefined) ? firstOrLast : randType;

    // Body for API call
    let body: WordListRequestBody = {
        debug: DEBUG,
        uuid: uuid,
        number: numOptions + 2,
        random_cat: random_cat,
        check_similar: true
    };

    // Apply word difficulty filter if present
    if (wordDifficulty !== undefined) body.difficulty = wordDifficulty;

    // Apply word category filter if present
    if (category !== undefined) body.category = category;

    let words: Array<{ word: string, date: string, images: Array<string>, reviewText: string, audio: string, difficulty: string }> = [];

    // Get word list
    try {
        let response = await axios.post(apiURI + '/wordsupdated/list2', body, ENVIRONMENT !== 'production' ? { auth: { username: "igins", password: "llc" } } : {});

        let wordData: Array<WordJSON> = response.data.data;
        for (let wordID in wordData) {
            words.push({
                word: wordData[wordID].word,
                date: wordData[wordID].researched_date,
                images: wordigins_chooseRand(wordData[wordID].images)[0],
                reviewText: wordData[wordID].sentence,
                audio: wordData[wordID].audio,
                difficulty: wordData[wordID].difficulty
            });
        }
    } catch (e) {
        throw (e);
    }

    let questionsArr: Array<QuestionData> = [];
    for (let i = 0; i < words.length; i += (numOptions - 1)) {
        let options: Array<string> = [];
        let optionsDates: { [key: string]: string } = {};

        // Track relevant values
        let max = 0,
            min: number = Number.MAX_SAFE_INTEGER,
            maxOption: string = '',
            minOption: string = '',
            maxOptionDate: number,
            minOptionDate: number,
            maxImages: Array<string> = [],
            minImages: Array<string> = [],
            maxText: string = '',
            minText: string = '',
            maxAudio: string = '',
            minAudio: string = '',
            minDiff: string = '',
            maxDiff: string = '';
        for (let j = 0; j < (numOptions - 1); j++) {
            options.push(words[i + j].word);
            optionsDates[words[i + j].word] = words[i + j].date;
            if (parseInt(words[i + j].date) < min) {
                min = parseInt(words[i + j].date);
                minOption = words[i + j].word;
                minOptionDate = parseInt(words[i + j].date);
                minImages = words[i + j].images;
                minText = words[i + j].reviewText;
                minAudio = words[i + j].audio;
                minDiff = words[i + j].difficulty;
            }
            if (parseInt(words[i + j].date) > max) {
                max = parseInt(words[i + j].date);
                maxOption = words[i + j].word;
                maxOptionDate = parseInt(words[i + j].date);
                maxImages = words[i + j].images;
                maxText = words[i + j].reviewText;
                maxAudio = words[i + j].audio;
                maxDiff = words[i + j].difficulty;
            }
        }

        let maxImgUrl = (Array.isArray(maxImages)) ? wordigins_chooseRand(maxImages)[0] : maxImages;
        let minImgUrl = (Array.isArray(minImages)) ? wordigins_chooseRand(minImages)[0] : minImages;

        let question: QuestionData = {
            type: type,
            options: options,
            subject: (type === 'first') ? minOption : maxOption,
            answer: (type === 'first') ? minOption : maxOption,
            optionsDates: optionsDates,
            subjectDiff: (type === 'first') ? minDiff : maxDiff,
            reviewText: (type === 'first') ? minText : maxText,
            reviewImgURL: (type === 'first') ? minImgUrl : maxImgUrl,
            audioURL: (type === 'first') ? minAudio : maxAudio,
        }
        question.options = question.options.filter((option) => {
            return option !== undefined;
        });

        let validQuestion = true;

        if (type === 'first') {
            words.forEach(word => {
                if (word.word !== minOption) {
                    if (parseInt(word.date) === minOptionDate) {
                        validQuestion = false;
                    }
                }
            });
        } else if (type === 'last') {
            words.forEach(word => {
                if (word.word !== maxOption) {
                    if (parseInt(word.date) === maxOptionDate) {
                        validQuestion = false;
                    }
                }
            });
        }

        //sort options alphabetically without articles
        question.options.sort(function (a, b) {
            if (a !== undefined && b !== undefined) {
                for (var i = 0; i < ARTICLES.length; i++) {
                    a = a.replace(ARTICLES[i] + " ", "");
                    b = b.replace(ARTICLES[i] + " ", "");
                }

                return (a > b) ? 1 : -1;
            }

            return 0;
        });

        if (validQuestion) {
            if (question.options.length === (numOptions - 1)) questionsArr.push(question);
        }
    }

    return questionsArr;
}

/**
 *  Returns Date-Range questions
 * 
 *  @param  {string}    questionDifficulty  Determines difficulty of option format
 *  @param  {string}    includeOrExclude    Value 'include' or 'exclude' can be passed to force a type, default false causes random choice
 *  @param  {string}    wordDifficulty      Optional API query for word difficulty filter
 *  @param  {string}    category            Optional API query for word category filter
 *  @param  {string}    debugWord           Word to be guaranteed as subject
 *  @return {promise}   promise             Resolves with question object or array of questions, Rejects with any error       
*/
//on incorrect for both, need to send word
async function buildDateRangeQuestions(uuid: string, questionDifficulty: string, random_cat: boolean, includeOrExclude?: string, wordDifficulty?: string, category?: string, debugWord?: string, numOptions: number = 1): Promise<Array<QuestionData>> {
    // Set type, random if not passed
    let randType = (Math.floor(Math.random() * 2)) ? 'include' : 'exclude';
    let type = (includeOrExclude) ? includeOrExclude : randType;

    // Create RegExp to filter by random century, decade, half-century. Default century.
    let rand = Math.floor(Math.random() * DEFAULT_DATE_RANGE) + DEFAULT_DATE_MIN,
        date_regexp = '',
        exclude_date_regexp = '',
        range = '',
        prefix: number;

    switch (questionDifficulty) {
        case 'moderate':
            prefix = Math.floor(rand / 50);
            // If 0, then filter tens digit [0-4], else [5-9]
            let regexp_end = ((prefix * 5) % 10) ? '[5-9]' : '[0-4]';
            date_regexp = '^' + Math.floor((prefix * 5) / 10) + regexp_end + '[0-9]';
            range = (prefix * 5) + '0-' + ((prefix + 1) * 5) + '0';
            exclude_date_regexp = date_regexp;
            break;
        case 'hard':
            prefix = Math.floor(rand / 10);
            date_regexp = '^' + prefix;
            range = prefix + '0-' + prefix + 9;
            exclude_date_regexp = date_regexp;
            break;
        default: // this will be easy
            prefix = Math.floor(rand / 100);
            date_regexp = '^' + prefix;
            range = (prefix + 1) + 'th century'
            if (category === undefined || category === "imported") {
                exclude_date_regexp = '^1[' + (prefix - 11) + "-" + (prefix - 9) + ']';
            } else {
                exclude_date_regexp = '^' + (prefix - 1) + '5[0-9]|' + (prefix - 1) + "[6-9][0-9]|" + (prefix) + '[0-9][0-9]|' + (prefix + 1) + '[0-4][0-9]';
            }
    }

    let correctBody: WordListRequestBody, incorrectBody: WordListRequestBody;
    if (type === 'include') {
        // API call bodies for odd one out, in range
        correctBody = {
            debug: DEBUG,
            uuid: uuid,
            number: 1,
            date_regexp: date_regexp,
            random_cat: random_cat,
        }
        incorrectBody = {
            debug: false,
            uuid: uuid,
            number: numOptions + 2,
            exclude_date_regexp: exclude_date_regexp,
            random_cat: random_cat,
            check_similar: true
        }
    } else if (type === 'exclude') {
        // API call bodies for odd one out, not in range
        incorrectBody = {
            debug: false,
            uuid: uuid,
            number: numOptions + 2,
            date_regexp: date_regexp,
            random_cat: random_cat,
            check_similar: true
        }
        correctBody = {
            debug: DEBUG,
            uuid: uuid,
            number: 1,
            exclude_date_regexp: exclude_date_regexp,
            random_cat: random_cat,
        }
    } else {
        return Promise.reject(new Error('Invalid question type: ' + type));
    }

    // Apply difficulty filter to all API calls if present
    if (wordDifficulty) {
        correctBody.difficulty = wordDifficulty;
        incorrectBody.difficulty = wordDifficulty;
    }

    // Apply category filter to all API calls if present
    if (category) {
        correctBody.category = category;
        incorrectBody.category = category;
    }

    // Apply debug word filter if present
    if (debugWord !== undefined) correctBody.debug_word = debugWord;

    let correctOptionsArr: Array<string> = [],
        imageURLArr: Array<string> = [],
        reviewTextArr: Array<string> = [],
        audioArr: Array<string> = [],
        subjectDiffArr: Array<string> = [],
        optionsDates: { [key: string]: string } = {};

    try {
        var correct_response = await axios.post(apiURI + '/wordsupdated/list2', correctBody, ENVIRONMENT !== 'production' ? { auth: { username: "igins", password: "llc" } } : {});

        let correct_wordData: Array<WordJSON> = correct_response.data.data;
        var correct = "";
        for (let wordID in correct_wordData) {
            correctOptionsArr.push(correct_wordData[wordID].word);
            imageURLArr.push(wordigins_chooseRand(correct_wordData[wordID].images)[0]);
            reviewTextArr.push(correct_wordData[wordID].sentence);
            audioArr.push(correct_wordData[wordID].audio);
            subjectDiffArr.push(correct_wordData[wordID].difficulty);
            correct = correct_wordData[wordID].word;
            optionsDates[correct_wordData[wordID].word] = correct_wordData[wordID].researched_date;
        }
        incorrectBody.correct = correct;
    } catch (e) {
        throw (e);
    }

    let incorrectOptionsArr: Array<string> = [];

    try {
        var incorrect_response = await axios.post(apiURI + '/wordsupdated/list2', incorrectBody, ENVIRONMENT !== 'production' ? { auth: { username: "igins", password: "llc" } } : {});

        let incorrect_wordData: Array<WordJSON> = incorrect_response.data.data;
        for (let wordID in incorrect_wordData) {
            incorrectOptionsArr.push(incorrect_wordData[wordID].word);
            optionsDates[incorrect_wordData[wordID].word] = incorrect_wordData[wordID].researched_date;
        }
    } catch (e) {
        throw (e);
    }

    let questionsArr: Array<QuestionData> = [];
    try {
        await Promise.all([correct_response, incorrect_response]).then(function () {
            correctOptionsArr.forEach(correct => {
                let options: Array<string | undefined> = [];
                options.push(correct);
                while (options.length < numOptions - 1) {
                    options.push(incorrectOptionsArr.pop());
                }

                // sort options alphabetically
                options.sort(function (a, b) {
                    if (a !== undefined && b !== undefined) {
                        for (var i = 0; i < ARTICLES.length; i++) {
                            a = a.replace(ARTICLES[i] + " ", "");
                            b = b.replace(ARTICLES[i] + " ", "");
                        }

                        return (a > b) ? 1 : -1;
                    }

                    return 0;
                });

                let question: QuestionData = {
                    type: 'date-range-' + type,
                    options: options,
                    subject: correct,
                    subjectDiff: subjectDiffArr.shift()!,
                    answer: correct,
                    optionsDates: optionsDates,
                    range: range,
                    reviewImgURL: imageURLArr.shift(),
                    reviewText: reviewTextArr.shift(),
                    audioURL: audioArr.shift(),
                };

                question.options = question.options.filter((option) => {
                    return option !== undefined;
                });

                // If the request couldn't find words that match the requirements, quietly fail - just don't push the question
                if (question.options.length === numOptions - 1) {
                    questionsArr.push(question);
                    if (debugWord) {
                        console.log("Built Question for ", debugWord, " Question type: ", "date-range");
                    }
                } else {
                    if (debugWord) {
                        console.log("Failed to build Question for ", debugWord);
                    }
                }
            });
        });
    } catch (e) {
        throw (e);
    }

    return questionsArr;
}

/**
 *  Returns origin questions e.g. "Which word came from/did not come from French"
 * 
 *  @param  {string}    questionDifficulty  Determines difficulty of option format
 *  @param  {string}    includeOrExclude    Value 'include' or 'exclude' can be passed to force a type, default false causes random choice
 *  @param  {string}    wordDifficulty      Optional API query for word difficulty filter
 *  @param  {string}    category            Optional API query for word category filter
 *  @param  {string}    debugWord           Word to be guaranteed as correct answer
 *  @return {promise}   promise             Resolves with question object or array of questions, Rejects with any error       
*/
//on incorrect for both, need to send word
async function buildOriginQuestions(uuid: string, questionDifficulty: string, includeOrExclude?: string, wordDifficulty?: string, category?: string, debugWord?: string, numOptions: number = 1): Promise<Array<QuestionData>> {
    // Set type, random if not passed
    let randType = (Math.floor(Math.random() * 2)) ? 'include' : 'exclude';
    let type = (includeOrExclude) ? includeOrExclude : randType;

    // API call bodies for odd one out, with subject language as origin
    let correctBody: WordListRequestBody = {
        debug:  DEBUG,
        uuid:   uuid,
        number: 1,
        min_origin_count: 1,
    }

    // Apply word difficulty filter if present
    // if (wordDifficulty !== undefined) correctBody.difficulty = wordDifficulty;

    // Apply word category filter if present
    if (category !== undefined) correctBody.category = category;

    // Apply debug word filter if present
    if (debugWord !== undefined) correctBody.debug_word = debugWord;

    let correctOptionsArr: Array<string> = [],
        imageURLArr: Array<string> = [],
        reviewTextArr: Array<string> = [],
        audioArr: Array<string> = [],
        possibleLangs: Array<Array<string>> = [],
        excludelangs: Array<Array<string>> = [],
        subjectDiffArr: Array<string> = [];

        if (wordDifficulty !== undefined) correctBody.origin_difficulty = wordDifficulty;

    try {
        var correct_response = await axios.post(apiURI + '/wordsupdated/list2', correctBody, ENVIRONMENT !== 'production' ? { auth: {username: "igins", password: "llc"}} : {});

        let correct_wordData: Array<WordJSON> = correct_response.data.data;
        var correct = '';
        for (let wordID in correct_wordData) {
            correctOptionsArr.push(correct_wordData[wordID].word);
            imageURLArr.push(wordigins_chooseRand(correct_wordData[wordID].images)[0]);
            reviewTextArr.push(correct_wordData[wordID].sentence);
            audioArr.push(correct_wordData[wordID].audio);
            subjectDiffArr.push(correct_wordData[wordID].difficulty);

            // Push array of possible subject langs language_of_origin that don't appear in origin_exclude
            possibleLangs.push(correct_wordData[wordID].language_of_origin);
            excludelangs.push(correct_wordData[wordID].origin_exclude);
            correct = correct_wordData[wordID].word;

            //console.log("length: ", correct_wordData);
            //console.log(correct_wordData[wordID].word, " ", "possible: ", possibleLangs[0]);
            //console.log(correct_wordData[wordID].word, " ", "exclude: ", excludelangs[0]);
        }
    } catch (e) {
        throw (e);
    }
    let allPossibleLangs = possibleLangs.flat();
    let allExcludeLangs = possibleLangs[0].concat(excludelangs[0]);
    

    // Initial definition of num subject langs limited by difficulty
    let maxNumSubjectLangs = (questionDifficulty === 'easy') ? 1 : (questionDifficulty === 'moderate') ? 2 : undefined;

    let subjectLangs: Array<string> = [];

    let incorrectBody: WordListRequestBody;
    if (type === 'include') {
        // refined max based on number of languages returned 

        incorrectBody = {
            debug:  false,
            uuid:   uuid,
            number: numOptions + 2,
            exclude_lang: JSON.stringify(allPossibleLangs),
            origin_exclude: JSON.stringify(allPossibleLangs),
            min_origin_count: 1,
            check_similar: true,
            correct: correct
        }

        if (wordDifficulty !== undefined) incorrectBody.origin_difficulty = wordDifficulty;
    } else if (type === 'exclude') {
        // Get all common languages that are not origin languages of the correct answer
        let filteredLangs = COMMON_ORIGIN_LANGUAGES.filter(el => allExcludeLangs.indexOf(el) < 0);

        // refine max based on number of languages found
        let numSubjectLangs = (maxNumSubjectLangs && maxNumSubjectLangs <= allExcludeLangs.length) ?
            maxNumSubjectLangs :
            (allExcludeLangs.length <= filteredLangs.length) ?
                allExcludeLangs.length :
                filteredLangs.length;

        // maybe choose at random from within that range, 0 excluded
        // numSubjectLangs = Math.floor(Math.random() * (numSubjectLangs - 1)) + 1;

        // Get common linages and remove all languages associated with the correct answer
        let filteredLiniages: Array<Array<string>> = [];
        COMMON_ORIGIN_LINIAGES.forEach(lineage => {
            filteredLiniages.push(lineage.filter(el => allExcludeLangs.indexOf(el) < 0));
        });
        // Use only lineages that contain at least the number of subject langs we want
        filteredLiniages = filteredLiniages.filter(el => el.length >= numSubjectLangs);

        // Get random set of subject languages from those available, limited by the above logic
        if (filteredLiniages.length > 0) {
            // Try to limit to a common lineage
            subjectLangs = wordigins_chooseRand(wordigins_chooseRand(filteredLiniages)[0], numSubjectLangs);
        } else {
            // Fallback on random common languages if this is not possible
            subjectLangs = wordigins_chooseRand(filteredLangs, numSubjectLangs);
        }

        // API call bodies for odd one out, without subject language as origin
        incorrectBody = {
            debug:  false,
            uuid:   uuid,
            number: numOptions + 2,
            origin_lang: JSON.stringify(subjectLangs),
            min_origin_count: subjectLangs.length,
            check_similar: true,
            correct: correct
        }

    } else {
        return Promise.reject(new Error('Invalid question type: ' + type));
    }
    
    // Apply word difficulty filter if present
     if (wordDifficulty !== undefined) incorrectBody.origin_difficulty = wordDifficulty;

    // Apply word category filter if present
    if (category !== undefined) incorrectBody.category = category;

    let incorrectOptionsArr: Array<string> = [];
    try {
        var incorrect_response = await axios.post( apiURI +'/wordsupdated/list2', incorrectBody, ENVIRONMENT !== 'production' ? { auth: {username: "igins", password: "llc"}} : {});
        let incorrect_wordData: Array<WordJSON> = incorrect_response.data.data;
        for (let wordID in incorrect_wordData) {
            incorrectOptionsArr.push(incorrect_wordData[wordID].word);
        }
    } catch (e) {
        throw (e);
    }

    let questionsArr: Array<QuestionData> = [];
    try {
        await Promise.all( [correct_response, incorrect_response] ).then(function () {
            correctOptionsArr.forEach(correct => {
                let options: Array<string | undefined> = [];
                options.push(correct);

                while (options.length < numOptions) {
                    options.push(incorrectOptionsArr.pop());
                }

                //sort options alphabetically
                options.sort(function (a,b) {
                    if (a !== undefined && b !== undefined) {
                        for (var i = 0; i < ARTICLES.length; i++) {
                            a = a.replace(ARTICLES[i] + " ", "");
                            b = b.replace(ARTICLES[i] + " ", "");
                        }
                
                        return (a > b) ? 1 : -1;
                    }
                
                    return 0;
                });

                //Set correct number of options based on difficulty selection and available languages for the answer if type is include
                if (type === 'include') {
                    let wordLangs = possibleLangs.shift();
                    if (questionDifficulty === 'hard') {
                        if (wordLangs) {
                            subjectLangs = wordLangs;
                            subjectLangs.sort();
                        }
                    } else if (questionDifficulty === 'moderate' && wordLangs && wordLangs[0] && wordLangs[1]) {
                        subjectLangs = wordLangs.slice(0,2);
                        subjectLangs.sort();
                        
                    } else {
                        if (wordLangs && wordLangs[0]) {
                            subjectLangs = [wordLangs[0]];
                            subjectLangs.sort();

                        }
                    }
                }
                if ( questionDifficulty === 'easy' ) {
                    //add clarifying text around certain languages (Update this when new langauges are added)
                    let langsFormatOne: Array<string>;
                    langsFormatOne = ['abenaki','afrikaans','algonquian','aramaic','balti','bengali','breton','catalan','cree','czech','gujarati','hindi','ilocano','inuit','javanese','lenape','malay','marquesan','nahuatl','occitan','ojibwa','quechua','tagalog','taino','tamil','tongan','urdu'];
                    
                    let langsFormatTwo: Array<string>;
                    langsFormatTwo = ['australian','croatian','hawaiian','irish','maori','persian','scots','serbian','tahitian',];
            
                    subjectLangs.forEach(function(lang, index) {
                        if (langsFormatOne.includes(lang)) {
                            subjectLangs[index] = "the language " + lang;
                        } else if  (langsFormatTwo.includes(lang)) {
                            subjectLangs[index] = "the " + lang + " language";
                        } else if (lang === 'english') {
                            subjectLangs[index] = 'modern English'
                        }
                    });
                }

                let question: QuestionData = {
                    type: 'origin-' + type,
                    options: options,
                    subject: correct,
                    subjectDiff: subjectDiffArr.shift()!,
                    subjectLangs: subjectLangs,
                    answer: correct,
                    reviewImgURL: imageURLArr.shift(),
                    reviewText: reviewTextArr.shift(),
                    audioURL: audioArr.shift()
                };

                question.options = question.options.filter((option) => {
                    return option !== undefined;
                });

                // If the request couldn't find words that match the requirements, quietly fail - just don't push the question
                if (question.options.length === numOptions) {
                    questionsArr.push(question);
                    if (debugWord) {
                        console.log("Built Question for ", debugWord, " Question type: ", "orgin");
                    }
                } else {
                    if (debugWord) {
                        console.log("Failed to build Question for ", debugWord);
                    }
                }
            });
        });
    } catch (e) {
        throw(e);
    }

    return questionsArr;
}

/**
 *  Returns multi origin questions e.g. "Which languages did WORD come from?" -> "French, Middle English"
 * 
 *  @param  {string}    questionDifficulty  Determines difficulty of option format
 *  @param  {string}    wordDifficulty      Optional API query for word difficulty filter
 *  @param  {string}    category            Optional API query for word category filter
 *  @param  {string}    debugWord           Word to be guaranteed as subject
 *  @return {promise}   promise             Resolves with question object or array of questions, Rejects with any error       
*/
//no check needed
async function buildOriginMultiQuestions(uuid: string, questionDifficulty: string, random_cat: boolean, wordDifficulty?: string, category?: string, numOptions: number = 1): Promise<Array<QuestionData>> {
    // Mod year by number according to difficulty, default easy (range of 50 years)
    let numAnswers: number = 1;
    if (questionDifficulty === 'moderate') numAnswers = 2;
    if (questionDifficulty === 'hard') numAnswers = 3;

    // Body of API call
    let body: WordListRequestBody = {
        debug:  DEBUG,
        uuid:   uuid,
        number: 1,
        min_origin_count: numAnswers,
        origin_combos: true,
        random_cat: random_cat,
    }

    // Apply word difficulty filter if present
    if (wordDifficulty !== undefined) body.difficulty = wordDifficulty;

    // Apply word category filter if present
    if (category !== undefined) body.category = category;

    // Apply debug word filter if present
    //if (debugWord !== undefined) body.debug_word = debugWord;

    // Helper function to process formatting for language names
    function formatLangNames (langs: Array<string>): Array<string> {
        langs.forEach((lang, i) => {
            // Remove hyphens
            lang = lang.replace('-', ' ');
      
            // Capitalize all first letters
            let words = lang.split(' ');
            words.forEach((word, j) => {
                words[j] = word[0].toUpperCase() + word.slice(1);
            });

            langs[i] = words.join(' ');
        });
      
        return langs;
    }

    let words: Array<WordJSON> = [];
    let origin_combos_moderate: Array<Array<string>> = [];
    let origin_combos_hard: Array<Array<string>> = [];
    let origin_easy_langs: Array<string> = [];
    try {
        let response = await axios.post(apiURI +'/wordsupdated/list2', body, ENVIRONMENT !== 'production' ? { auth: {username: "igins", password: "llc"}} : {});
        words = response.data.data;
        origin_combos_moderate = response.data.origin_combos_moderate;
        origin_combos_hard = response.data.origin_combos_hard;
        origin_easy_langs = response.data.origin_easy_langs;
    } catch (e) {
        throw(e);
    }

    let questionsArr: Array<QuestionData> = [];
    for (let wordID in words) {
        let originLangs: Array<string> = words[wordID].language_of_origin,
            reviewText:  string        = words[wordID].sentence,
            imageURL:    string        = wordigins_chooseRand(words[wordID].images)[0];
        
        // Alternate logic to choose randomly
        // let answerLangs: Array<string> = (originLangs.length > numAnswers) ? wordigins_chooseRand(originLangs, numAnswers) : originLangs;

        // Choose enough languages for answer by least-frequent languages of origin
        let originLangsByFrequency: Array<string> = ORIGIN_LANGUAGES.filter(el => originLangs.indexOf(el) >= 0);
        let answerLangs: Array<string> = [];
        for (let i = 0; i < numAnswers; i++) {
            let lang = originLangsByFrequency.pop();
            if (lang) {
                answerLangs.push(lang);
            }
        }

        //reverse answer langs array so it is alphabetical in game
        answerLangs = answerLangs.reverse();

        // Declare options with correct option
        let formattedAnswer = formatLangNames([...answerLangs]).join(', ');
        let options: Array<string> = [formattedAnswer];

        // Get all possible origin languages not already associated with the correct answer
        let associatedLangs = originLangs.concat(words[wordID].origin_exclude);
        let incorrectLangs: Array<string> = [];
        if ( origin_easy_langs ) {
            incorrectLangs = origin_easy_langs.filter(el => formatLangNames(associatedLangs).indexOf(el) < 0);
        } else {
            incorrectLangs = ORIGIN_LANGUAGES.filter(el => associatedLangs.indexOf(el) < 0);
            incorrectLangs = formatLangNames(incorrectLangs);
        }
        if (questionDifficulty === 'easy') {
            while (options.length < numOptions) {
                let incorrectOptions = wordigins_chooseRand(incorrectLangs, numOptions - 1);
                for (let incorrectLang in incorrectOptions) {
                    options.push(incorrectOptions[incorrectLang]);
                }
            }
        } else if (questionDifficulty === 'moderate') {
            let addOption = true;
            let i = 0;
            while (options.length < numOptions) {
                i++;
                addOption = true;
                let incorrectOptions: Array<Array<string>> = wordigins_chooseRand(origin_combos_moderate, 1);
                let incorrectOptionsArr = incorrectOptions[0];
                for (let currentLang in incorrectOptionsArr) {
                    for (let option in options) {
                        let optionsArr = options[option].split(', ');
                        for (let language in optionsArr) {
                            if ( optionsArr[language] === incorrectOptionsArr[currentLang] ) {
                                addOption = false;
                            }
                        }
                    }
                }
                if ( addOption === true ) {
                    let incorrectOptionsTrimmed = wordigins_chooseRand(incorrectOptionsArr, 2);
                    incorrectOptionsTrimmed.sort();
                    let validIncorrectOption = formatLangNames([...incorrectOptionsTrimmed]).join(', ');
                    options.push(validIncorrectOption);
                }
                if ( i > 200 ) {
                    break
                }
            }
        } else if (questionDifficulty === 'hard') {            
            let addOption = true;
            let i = 0;
            while (options.length < numOptions) {
                i++;
                addOption = true;
                let incorrectOptions: Array<Array<string>> = wordigins_chooseRand(origin_combos_hard, 1);
                let incorrectOptionsArr = incorrectOptions[0];
                for (let currentLang in incorrectOptionsArr) {
                    for (let option in options) {
                        let optionsArr = options[option].split(', ');
                        for (let language in optionsArr) {
                            if ( optionsArr[language] === incorrectOptionsArr[currentLang] ) {
                                addOption = false;
                            }
                        }
                    }
                }
                if ( addOption === true ) {
                    let incorrectOptionsTrimmed = wordigins_chooseRand(incorrectOptionsArr, 3);
                    incorrectOptionsTrimmed.sort();
                    let validIncorrectOption = formatLangNames([...incorrectOptionsTrimmed]).join(', ');
                    options.push(validIncorrectOption);
                }
                if ( i > 200 ) {
                    break
                }
            }
        }

        //sort options alphabetically
        options.sort(function (a,b) {
            if (a !== undefined && b !== undefined) {
                for (var i = 0; i < ARTICLES.length; i++) {
                    a = a.replace(ARTICLES[i] + " ", "");
                    b = b.replace(ARTICLES[i] + " ", "");
                }
        
                return (a > b) ? 1 : -1;
            }
        
            return 0;
        });

        let question: QuestionData = {
            type: 'origin-multi',
            reviewText: reviewText,
            reviewImgURL: imageURL,
            category: category,
            subject: words[wordID].word,
            subjectDiff: words[wordID].difficulty,
            options: options,
            answer: formattedAnswer,
            audioURL: words[wordID].audio
        };
        question.options = question.options.filter((option) => {
            return option !== undefined;
        });

        if (question.options.length === numOptions) questionsArr.push(question);
    }

    return questionsArr;
}

/**
 *  Returns subcategory questions e.g. "Which word came from/did not come from a play"
 * 
 *  @param  {string}    questionDifficulty  Determines difficulty of option format
 *  @param  {string}    includeOrExclude    Value 'include' or 'exclude' can be passed to force a type, default false causes random choice
 *  @param  {string}    wordDifficulty      Optional API query for word difficulty filter
 *  @param  {string}    category            Optional API query for word category filter
 *  @param  {string}    debugWord           Word to be guaranteed as correct answer
 *  @return {promise}   promise             Resolves with question object or array of questions, Rejects with any error       
*/
//check on incorrect body for both, need to send word
async function buildSubcatQuestions(uuid: string, questionDifficulty: string, random_cat: boolean, includeOrExclude?: string, wordDifficulty?: string, category?: string, debugWord?: string, numOptions: number = 1): Promise<Array<QuestionData>> {
    // Set type, random if not passed
    let randType = (Math.floor(Math.random() * 2)) ? 'include' : 'exclude';
    let type = (includeOrExclude) ? includeOrExclude : randType;
    let correctBody: WordListRequestBody, incorrectBody: WordListRequestBody;

    correctBody = {
        debug:  DEBUG,
        uuid:   uuid,
        number: 1,
        category: category,
        usesubcat: true,
        random_cat: random_cat,
    }

    // Apply word difficulty and category filters if present
    if (wordDifficulty !== undefined) correctBody.difficulty = wordDifficulty;

    if (category !== undefined) correctBody.category = category;

    // Apply debug word filter if present
    if (debugWord !== undefined) {
       correctBody.debug_word = debugWord;
    }

    let correctOptionsArr: Array<string> = [],
        imageURLArr: Array<string> = [],
        reviewTextArr: Array<string> = [],
        audioArr: Array<string> = [],
        correct_subcats_exclude: Array<string> = [],
        articleArr: Array<string> = [],
        subcatArr: Array<string> =[],
        subjectDiffArr: Array<string> = [];

    try {
        var correct_response = await axios.post(apiURI + '/wordsupdated/list2', correctBody, ENVIRONMENT !== 'production' ? { auth: {username: "igins", password: "llc"}} : {});

        let correct_wordData: Array<WordJSON> = correct_response.data.data;
        var correct = "";
        for (let wordID in correct_wordData) {
            correctOptionsArr.push(correct_wordData[wordID].word);
            imageURLArr.push(wordigins_chooseRand(correct_wordData[wordID].images)[0]);
            reviewTextArr.push(correct_wordData[wordID].sentence);
            audioArr.push(correct_wordData[wordID].audio);
            articleArr.push(correct_wordData[wordID].article);
            subcatArr.push(correct_wordData[wordID].subcat);
            subjectDiffArr.push(correct_wordData[wordID].difficulty);
            correct_subcats_exclude = [...new Set([...correct_wordData[wordID].subcat_exclude,...correct_subcats_exclude])];
            correct = correct_wordData[wordID].word;

        }

    } catch (e) {
        throw (e);
    } 

    let subcat_flat = subcatArr.flat();
    let correct_subcats_exclude_flat = correct_subcats_exclude.flat();
    if (subcat_flat.includes("music")) {
        subcat_flat.push("musical-instrument");
    } else if (subcat_flat.includes("musical-instrument")) {
        subcat_flat.push("music");
    }
        if (type === 'include') {

            incorrectBody = {
                debug:  false,
                uuid:   uuid,
                category: category,
                correct_subcats: JSON.stringify(subcat_flat),
                exclude_subcats: JSON.stringify(subcat_flat),
                number: numOptions + 2,
                usesubcat: true,
                random_cat: random_cat,
                check_similar: true,
                correct: correct
            }

        // Apply word difficulty filter if present
            if (wordDifficulty !== undefined) incorrectBody.difficulty = wordDifficulty;

            // Apply word category filter if present
            if (category !== undefined) incorrectBody.category = category;

            let incorrectOptionsArr: Array<string> = [];
            try {
                var incorrect_response = await axios.post( apiURI +'/wordsupdated/list2', incorrectBody, ENVIRONMENT !== 'production' ? { auth: {username: "igins", password: "llc"}} : {});

                let incorrect_wordData: Array<WordJSON> = incorrect_response.data.data;
                for (let wordID in incorrect_wordData) {
                    incorrectOptionsArr.push(incorrect_wordData[wordID].word);
                }
            } catch (e) {
                throw (e);
            }

            let questionsArr: Array<QuestionData> = [];
            try {
                await Promise.all( [correct_response, incorrect_response] ).then(function () {
                    correctOptionsArr.forEach(correct => {
                        let current_subcat = subcat_flat.shift();
                        if (current_subcat === "musical-instrument") {
                            current_subcat = "musical instrument";
                        }
                        let options: Array<string | undefined> = [];
                        if (correct === "musical-instrument") {
                            correct = "musical instrument";
                        }
                        options.push(correct);

                        while (options.length < numOptions) {
                            let incorrect = incorrectOptionsArr.pop();
                            if (incorrect === "musical-instrument") {
                                incorrect = "musical instrument";
                            }
                            options.push(incorrect);
                        }

                        //sort options alphabetically
                        options.sort(function (a,b) {
                            if (a !== undefined && b !== undefined) {
                                for (var i = 0; i < ARTICLES.length; i++) {
                                    a = a.replace(ARTICLES[i] + " ", "");
                                    b = b.replace(ARTICLES[i] + " ", "");
                                }
                        
                                return (a > b) ? 1 : -1;
                            }
                        
                            return 0;
                        });

                        let question: QuestionData = {
                            type: 'subcat-' + type,
                            options: options,
                            subject: correct,
                            answer: correct,
                            subcat: current_subcat,
                            reviewImgURL: imageURLArr.shift(),
                            reviewText: reviewTextArr.shift(),
                            subjectDiff: subjectDiffArr.shift()!,
                            audioURL: audioArr.shift(),
                            article: articleArr.shift()
                        };

                        question.options = question.options.filter((option) => {
                            return option !== undefined;
                        });

                        // If the request couldn't find words that match the requirements, quietly fail - just don't push the question
                        if (question.options.length === numOptions) {
                            questionsArr.push(question);
                            if (debugWord) {
                                console.log("Built Question for ", debugWord, " Question type: ", "subcat-exclude");
                            }
                        } else {
                            if (debugWord) {
                                console.log("Failed to build Question for ", debugWord);
                            }
                        }
                    });
                });
            } catch (e) {
                throw(e);
            }
            return questionsArr;

        } else if (type === 'exclude') {
           
            let subcats_to_exclude = [...new Set([...subcat_flat,...correct_subcats_exclude_flat])];
            let filtered_subcats = SUBCATEGORIES.filter(item => subcats_to_exclude.indexOf(item) < 0);
            let subcat = wordigins_chooseRand(filtered_subcats)[0];
           
            incorrectBody = {

                debug:  false,
                uuid:   uuid,
                category: category,
                number: numOptions + 2,
                subcat: subcat,
                random_cat: random_cat,
                check_similar: true,
                correct: correct
            }

        // Apply word difficulty filter if present
            if (wordDifficulty !== undefined) incorrectBody.difficulty = wordDifficulty;

            // Apply word category filter if present
            if (category !== undefined) incorrectBody.category = category;

            let incorrectOptionsArr: Array<string> = [];
            let exclude_articleArr: Array<string> = []
            try {
                var incorrect_response = await axios.post( apiURI +'/wordsupdated/list2', incorrectBody, ENVIRONMENT !== 'production' ? { auth: {username: "igins", password: "llc"}} : {});

                let incorrect_wordData: Array<WordJSON> = incorrect_response.data.data;
                for (let wordID in incorrect_wordData) {
                    incorrectOptionsArr.push(incorrect_wordData[wordID].word);
                    exclude_articleArr.push(incorrect_wordData[wordID].article);
                }
            } catch (e) {
                throw (e);
            }
            let questionsArr: Array<QuestionData> = [];
            try {
                await Promise.all( [correct_response, incorrect_response] ).then(function () {
                    correctOptionsArr.forEach(correct => {
                        if (subcat === "musical-instrument") {
                            subcat = "musical instrument";
                        }
                        let options: Array<string | undefined> = [];
                        if (correct === "musical-instrument") {
                            correct = "musical instrument";
                        }
                        options.push(correct);

                        while (options.length < numOptions) {
                            let incorrect = incorrectOptionsArr.pop();
                            if (incorrect === "musical-instrument") {
                                incorrect = "musical instrument";
                            }
                            options.push(incorrect);
                        }

                        //sort options alphabetically
                        options.sort(function (a,b) {
                            if (a !== undefined && b !== undefined) {
                                for (var i = 0; i < ARTICLES.length; i++) {
                                    a = a.replace(ARTICLES[i] + " ", "");
                                    b = b.replace(ARTICLES[i] + " ", "");
                                }
                        
                                return (a > b) ? 1 : -1;
                            }
                        
                            return 0;
                        });

                        let question: QuestionData = {
                            type: 'subcat-' + type,
                            options: options,
                            subject: correct,
                            answer: correct,
                            subcat: subcat,
                            reviewImgURL: imageURLArr.shift(),
                            reviewText: reviewTextArr.shift(),
                            subjectDiff: subjectDiffArr.shift()!,
                            audioURL: audioArr.shift(),
                            article: exclude_articleArr.shift()
                        };

                        question.options = question.options.filter((option) => {
                            return option !== undefined;
                        });

                        // If the request couldn't find words that match the requirements, quietly fail - just don't push the question
                        if (question.options.length === numOptions) {
                            questionsArr.push(question);
                            if (debugWord) {
                                console.log("Built Question for ", debugWord, " Question type: ", "subcat-exclude");
                            }
                        } else {
                            if (debugWord) {
                                console.log("Failed to build Question for ", debugWord);
                            }
                        }
                    });
                });
            } catch (e) {
                throw(e);
            }
            return questionsArr;


     } else {
        return Promise.reject(new Error('Invalid question type: ' + type));
    }
}

/**
 *  Returns subcat origin questions e.g. "The word Jumbo comes from:" -> "an animal"
 * 
 *  @param  {string}    questionDifficulty  Determines difficulty of option format
 *  @param  {string}    wordDifficulty      Optional API query for word difficulty filter
 *  @param  {string}    category            Optional API query for word category filter
 *  @param  {string}    debugWord           Word to be guaranteed as subject
 *  @return {promise}   promise             Resolves with question object or array of questions, Rejects with any error       
*/
//no check needed
async function buildSubcatOriginQuestions(uuid: string, questionDifficulty: string, random_cat: boolean, wordDifficulty?: string, category?: string, debugWord?: string, numOptions: number = 1): Promise<Array<QuestionData>> {
    // Body of API call
    let correctBody: WordListRequestBody = {
        debug:  DEBUG,
        uuid:   uuid,
        number: 1,
        usesubcat: true,
        random_cat: random_cat,
    }


    // Apply word difficulty filter if present
    if (wordDifficulty !== undefined) correctBody.difficulty = wordDifficulty;

    // Apply word category filter if present
    if (category !== undefined) correctBody.category = category;

    // Apply debug word filter if present
    if (debugWord !== undefined) correctBody.debug_word = debugWord;

    let words: Array<WordJSON> = [],
        questionsArr: Array<QuestionData> = [],
        correct_subcats_exclude: Array<string> = [],
        correctOptionsArr: Array<string> = [],
        subcatArr: Array<string> =[],
        imageURLArr: Array<string> = [],
        reviewTextArr: Array<string> = [],
        audioArr: Array<string> = [],
        subjectDiffArr: Array<string> = [],
        articleArr: Array<string> = []
    try {
        var correctResponse = await axios.post(apiURI +'/wordsupdated/list2', correctBody, ENVIRONMENT !== 'production' ? { auth: {username: "igins", password: "llc"}} : {});
        words = correctResponse.data.data;

        for (let wordID in words) {
            subcatArr.push(words[wordID].subcat);
            imageURLArr.push(wordigins_chooseRand(words[wordID].images)[0]);
            correctOptionsArr.push(words[wordID].word);
            articleArr.push(words[wordID].article);
            subjectDiffArr.push(words[wordID].difficulty);
            reviewTextArr.push(words[wordID].sentence);
            audioArr.push(words[wordID].audio);
            correct_subcats_exclude = [...new Set([...words[wordID].subcat_exclude,...correct_subcats_exclude])]
        }

    } catch (e) {
        throw(e);
    }
    
    let subcat_flat = subcatArr.flat();
    let correct_subcats_exclude_flat = correct_subcats_exclude.flat();
    let subcats_to_exclude = [...new Set([...subcat_flat,...correct_subcats_exclude_flat])];

    let incorrectBody: WordListRequestBody = {
        debug:  DEBUG,
        uuid:   uuid,
        number: 3,
        correct_subcats: JSON.stringify(subcats_to_exclude),
        usesubcat: true,
        random_cat: random_cat,
    }

        // QUESTION: will this limit answers to only the difficulty and if so, is there some kind
        //           of difficulty range in the API, rather than just either a single difficulty, or
        //            all difficulties?
        //          Seems like there  is in WordupdatedController, but not sure if it works
        //          random cat = always one difficulty
        
        // Apply word difficulty filter if present
        if (wordDifficulty !== undefined) incorrectBody.difficulty = wordDifficulty;


            let incorrectOptionsArr: Array<string> = [];
            let incorrectArticleArr: Array<string> = [];
            let incorrectSubcatArr: Array<string> =[];
            try {
                var incorrectResponse = await axios.post( apiURI +'/wordsupdated/list2', incorrectBody, ENVIRONMENT !== 'production' ? { auth: {username: "igins", password: "llc"}} : {});

                let incorrect_wordData: Array<WordJSON> = incorrectResponse.data.data;
                for (let wordID in incorrect_wordData) {
                    incorrectOptionsArr.push(incorrect_wordData[wordID].word);
                    incorrectArticleArr.push(incorrect_wordData[wordID].article);
                    incorrectSubcatArr.push(incorrect_wordData[wordID].subcat);
                }
            } catch (e) {
                throw (e);
            }

            //create arrays of unique articles and subcats
            let uniqueSubcatArr: string[] = [];
            let uniqueArticleArr: string[] = [];
            for (let i = 0; i < incorrectSubcatArr.length; i++) {
                if (!uniqueSubcatArr.includes(incorrectSubcatArr[i])) {
                    uniqueSubcatArr.push(incorrectSubcatArr[i]);
                    uniqueArticleArr.push(incorrectArticleArr[i]);
                }
              }

        try {
            await Promise.all( [correctResponse, incorrectResponse] ).then(function () {
                subcat_flat.forEach(correct => {
                    let options: Array<string | undefined> = [];
                    let correctArticle = articleArr.shift();
                    if (correct === "musical-instrument") {
                        correct = "musical instrument";
                    }
                    let correctFull = correct;
                    if (correctArticle) {
                        correctFull = correctArticle.slice(1, -1) + correct;
                    }
                    options.push(correctFull);
                    while (options.length < numOptions) {
                        let incorrectArticle = uniqueArticleArr.pop();
                        let incorrectSubcat = uniqueSubcatArr.pop();
                        if (incorrectSubcat === "musical-instrument") {
                            incorrectSubcat = "musical instrument";
                        }
                        if (incorrectArticle && incorrectSubcat) {
                            options.push(incorrectArticle.slice(1, -1) + incorrectSubcat);
                        } else if (incorrectSubcat){
                            options.push(incorrectSubcat);
                        } else {
                            break;
                        }
                    }

                    //sort options alphabetically
                    options.sort(function (a,b) {
                        if (a !== undefined && b !== undefined) {
                            for (var i = 0; i < ARTICLES.length; i++) {
                                a = a.replace(ARTICLES[i] + " ", "");
                                b = b.replace(ARTICLES[i] + " ", "");
                            }
                    
                            return (a > b) ? 1 : -1;
                        }
                    
                        return 0;
                    });

                    let subject = correctOptionsArr.shift();
                    if (subject ){
                        let question: QuestionData = {
                            type: 'subcat-multi',
                            options: options,
                            subject: subject,
                            answer: correctFull,
                            reviewImgURL: imageURLArr.shift(),
                            reviewText: reviewTextArr.shift(),
                            subjectDiff: subjectDiffArr.shift()!,
                            audioURL: audioArr.shift(),
                        };

                        question.options = question.options.filter((option) => {
                            return option !== undefined;
                        });

                        // If the request couldn't find words that match the requirements, quietly fail - just don't push the question
                        if (question.options.length === numOptions) {
                            questionsArr.push(question); 
                        }
                    }
                });
            });
        } catch (e) {
            throw(e);
        }

    return questionsArr;
}
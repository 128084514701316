/** Review Screen Component */

import React from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import { Howl } from 'howler';
import * as curlyquotes from 'curlyquotes';
import '../theme/Review.css';
import { IonModal } from '@ionic/react';
import TopBar       from '../components/TopBar';
import Question     from './Question';

import { NUM_QUESTIONS } from '../ts/environment';

import { ApplicationState, QuestionData, ScoreRecord } from '../ts/interfaces';
import withApplicationState from '../store';

import iconCorrect from '../assets/icons/ui/icon_correct.svg';
import iconIncorrect from '../assets/icons/ui/icon_incorrect.svg';
// import placeholderImg from '../assets/placeholder.png';

import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { Preferences } from '@capacitor/preferences';

import { correctResponseActionTypes, questionIndexActionTypes, showProgressActionTypes, submittedActionTypes } from '../ts/actionTypes';

interface ReviewProps {
  store:           ApplicationState;
  dispatch:        ({ type }: { type: string; payload?: any; }) => void;
};

interface ReviewState { 
  scrollable:   boolean;
  qReviewOpen:  boolean;
};

class Review extends React.Component<ReviewProps, ReviewState> {
  private _question: QuestionData;
  private _wordAudio: Howl | null = null;

  constructor (props: ReviewProps) {
    super(props);

    // Set current question data
    const { currentQuestionsArray, currentQuestionIndex } = this.props.store;
    this._question = currentQuestionsArray[currentQuestionIndex!];

    this.state = {
      scrollable:    false,
      qReviewOpen:   false,
    }

    // Load audio if exists
    let audioURL = this._question.audioURL;
    if (audioURL) {
      this._wordAudio = new Howl({
        src: [audioURL],
        format: ['mp3'],
      });
    }
  }

  async componentDidMount() {
    if (document.getElementById("review_text_wrapper")) {
      if (document.getElementById("review_text_wrapper")!.scrollHeight && document.getElementById("review_text_wrapper")!.clientHeight) {
        if (document.getElementById("review_text_wrapper")!.scrollHeight > document.getElementById("review_text_wrapper")!.clientHeight + 15) {
          this.setState({scrollable: true});
      }
    }
  }
  this.logQuizData()
}

  /**
   *  Log score to local storage
   */
  private async logLocalScore () {
    const { uuid, currentCategory, currentDifficulty, countCorrect} = this.props.store;
    
    let newDate = new Date().toString();

    // Get locally logged scores associated with current difficulty
    let difficultyKey = currentDifficulty + 'Scores';
    const difficultyRecords = await Preferences.get({key: difficultyKey});
    const difficultyValue = difficultyRecords.value;
    let time = await Preferences.get({key: 'currentTime'});
    let timeValue = time.value;
    let timeFinal: number = 9999000;
    if (timeValue !== null) {
      timeFinal = Math.round(+timeValue);
    }

    let difficultyScoreArray: Array<ScoreRecord> = [];
    if (difficultyValue !== null) {
      difficultyScoreArray = JSON.parse(difficultyValue);
    }

    // Push latest score to difficulty array
    difficultyScoreArray.push({
      uuid: uuid,
      initials: '',
      score: countCorrect, 
      num_questions: NUM_QUESTIONS,
      date: newDate,
      category: currentCategory || 'random',
      difficulty: currentDifficulty,
      game_time: timeFinal
    });

    // Get locally logged scores associated with current category
    let categoryKey = currentCategory + 'Scores';
    const categoryRecords = await Preferences.get({key: categoryKey});
    const categoryValue = categoryRecords.value;

    let categoryScoreArray: Array<ScoreRecord> = [];
    if (categoryValue !== null) {
      categoryScoreArray = JSON.parse(categoryValue);
    }

    // Push latest score to category array
    categoryScoreArray.push({
      uuid: uuid,
      initials: '',
      score: countCorrect, 
      num_questions: NUM_QUESTIONS,
      date: newDate,
      category: currentCategory || 'random',
      difficulty: currentDifficulty,
      game_time: timeFinal
    });

    // Overwrite locally stored scores with new values appended
    await Preferences.set({
      key: difficultyKey,
      value: JSON.stringify(difficultyScoreArray)
    });
    
    await Preferences.set({
      key: categoryKey,
      value: JSON.stringify(categoryScoreArray)
    });
  }

  /**
   *  State dispatches necessary to advance question
   */
  private nextQuestionDispatches () {
    this.props.dispatch({
      type: questionIndexActionTypes.INCREMENT_INDEX
    });

    this.props.dispatch({
      type: submittedActionTypes.SET_NOT_SUBMITTED
    });

    this.props.dispatch({
      type: correctResponseActionTypes.SET_INCORRECT
    });
  }

  closeqReview () {
    this.setState({
      qReviewOpen: false
    });

    this.props.dispatch({
      type: showProgressActionTypes.SET_SHOW
    });
  }

  openqReview () {
    this.setState({
      qReviewOpen: true
    });

    this.props.dispatch({
      type: showProgressActionTypes.SET_NOT_SHOW
    });
  }

  /** 
   *  Procedure to perform to advance to next question
   */
  async nextQuestionCallback () {
    const { currentQuestionsArray, currentQuestionIndex, platform, soundsOn, vibrationOn, uiAudio, focused } = this.props.store;

    if (focused && soundsOn) {
      const uiPopAudio: Howl = new Howl({
        src: ['assets/pop.mp3'],
        format: ['mp3'],
        volume: 0.3,
        onloaderror: (id, e) => console.log('failed: ' + id + ' msg: ' + e)
    });
    uiPopAudio.play();
    } else {
      if (soundsOn) uiAudio.play();
    }

    if (platform !== 'web' && vibrationOn) Haptics.impact({style: ImpactStyle.Light});

    if (currentQuestionIndex !== undefined && currentQuestionsArray.length === NUM_QUESTIONS) {

      // Only log a local score after the last question
      if (currentQuestionIndex === currentQuestionsArray.length - 1) {
        try {
          await this.logLocalScore();
        } catch (e) {
          console.error(e);
        }
        this.nextQuestionDispatches();
      } else {
        this.nextQuestionDispatches();
      }
    }
  }

  /**
   * Play word audio if set and enabled
   */
  playWordAudio () {
    const { focused } = this.props.store;
    if ( focused ) {
      let audioURL = this._question.audioURL;
      if (audioURL) {
        this._wordAudio = new Howl({
          src: [audioURL],
          format: ['mp3'],
        });
      }
    } else {
    }
    const { soundsOn } = this.props.store;
    if (soundsOn && this._wordAudio) {
      this._wordAudio.play();
    }
  }

  // Hides the scroll indicator when at bottom
  handleScroll = () => {
    if (document.getElementById("review_text_wrapper") && document.getElementById("scroll_indicator")) {
      if (((document.getElementById("review_text_wrapper")!.scrollTop + document.getElementById("review_text_wrapper")!.clientHeight) * 1.1) >= document.getElementById("review_text_wrapper")!.scrollHeight) {
        document.getElementById("scroll_indicator")!.style.borderLeft = '0px';
        document.getElementById("scroll_indicator")!.style.borderBottom = '0px';
      } else {
        //show scroll indicator if the user scrolls up
        this.setState({scrollable: true});
        if (document.getElementById("scroll_indicator")) {
          document.getElementById("scroll_indicator")!.style.borderLeft = '2px solid #fff';
          document.getElementById("scroll_indicator")!.style.borderBottom = '2px solid #fff';
      }
    }
  }
}

  /**
 *  Log question array, current progress, current score, difficulty, and category
 */
private logQuizData() {
  const { currentCategory, currentDifficulty, countCorrect, currentQuestionsArray, currentQuestionIndex, startTime, scoreSubmitted} = this.props.store;
  
//Store current game data
 Preferences.set({
    key: 'countCorrectKey',
    value: JSON.stringify(countCorrect)
  })
 Preferences.set({
    key: 'currentQuestionIndexKey',
    value: JSON.stringify(currentQuestionIndex)
  });
  Preferences.set({
    key: 'currentTime',
    value: JSON.stringify(new Date().getTime() - startTime)
  })

  //console.log("logged data");
  //If first question, save currentCategory, currentDifficulty, currentQuestionArray
  if (currentQuestionIndex === 0) {
   Preferences.set({
      key: 'savedGame',
      value: '1'
    });
   Preferences.set({
      key: 'currentCategoryKey',
      value: currentCategory
    });
   Preferences.set({
      key: 'currentDifficultyKey',
      value: currentDifficulty
    });
   Preferences.set({
      key: 'currentQuestionsArrayKey',
      value: JSON.stringify(currentQuestionsArray)
    });
  }
  Preferences.set({
    key: 'scoreSubmittedKey',
    value: JSON.stringify(scoreSubmitted)
  });
}

  render() {
    let { currentQuestionIndex, correctResponse, lastResponse, platform } = this.props.store;
    let { qReviewOpen } = this.state;
    let { reviewText, reviewImgURL, answer, subject, optionsDates } = this._question;
    const {scrollable} = this.state;

    if (!reviewText) {
      reviewText = '';
    }

    // Spotlight one word in reorder questions
    if (Array.isArray(answer)) {

      reviewText = 'Spotlight word: ' + subject + '! ' + reviewText;

      // Bold all instances of the subject

      let subjectRegexp = new RegExp(subject, 'i');
      reviewText = reviewText.replace(subjectRegexp, `<b class=subject style=text-transform:uppercase;>${subject}</b>`)

    } else if (optionsDates) {
      
      // Correct/Incorrect answer prefix
      let prefixText;
      if (!correctResponse) {
        prefixText = `The correct answer was ${answer}, which is first recorded in ${optionsDates[answer]}; you answered ${lastResponse}, first recorded in ${optionsDates[lastResponse]}. `;
      } else {
        prefixText = `You correctly answered ${answer}, which is first recorded in ${optionsDates[answer]}. `;
      }
      
      reviewText = prefixText + reviewText;
      let subjectRegexp = new RegExp(subject, 'i');
      reviewText = reviewText.replace(subjectRegexp, `<b class=subject style=text-transform:uppercase;>${subject}</b>`)
    } else {

      // Correct/Incorrect answer prefix
      let prefixText;
      if (!correctResponse) {
        prefixText = `The correct answer was ${answer}; you answered ${lastResponse}. `;
      } else {
        prefixText = `You correctly answered ${answer}. `;
      }
      
      reviewText = prefixText + reviewText;
      let subjectRegexp = new RegExp(subject, 'i');
      reviewText = reviewText.replace(subjectRegexp, `<b class=subject style=text-transform:uppercase;>${subject}</b>`)
    }

    // Smartquotes
    reviewText = curlyquotes.string(reviewText);
    return (
      <div className={`review-wrapper ${qReviewOpen ? 'review-hidden-ui' : ''}`}>
        <IonModal className="modal-fullscreen" id="qReview"   isOpen={qReviewOpen}   canDismiss={true} onDidDismiss={this.closeqReview.bind(this)}>
        { platform !== 'web' ? <div className='ad-buffer'></div> : <></> }
            <div className="topbar-wrapper">
              <TopBar categoryCallback={() => {/** Dummy Callback */}}
                            closeCallback={this.closeqReview.bind(this)}
                            showCategory={true}
                            showLogo={true}
                            showClose={true} />
            </div>
          <Question qReview={true} />
        </IonModal>
      { (correctResponse) ? 
        <div className="correct">
          <IonIcon className="header" icon={iconCorrect} onClick={() => this.openqReview()} />
        </div>
        : 
        <div className="incorrect">
          <IonIcon className="header" icon={iconIncorrect} onClick={() => this.openqReview()} />
        </div>
      }

      { (reviewImgURL) ? 
          <img src={reviewImgURL} alt={'Subject'} />
          :
          <></>
      }
      <div className="scroll-text-wrapper" >
        <div id="review_text_wrapper" className="review-text-wrapper" onScroll={() => this.handleScroll()} >
          <p onClick={() => this.playWordAudio()} dangerouslySetInnerHTML={{__html: reviewText!}}></p>
        </div>
        { (scrollable) ? 
            (document.getElementById("review_text_wrapper")) ? <div onClick={() => document.getElementById("review_text_wrapper")!.scrollTo({
              top: document.getElementById("review_text_wrapper")!.scrollTop + document.getElementById("review_text_wrapper")!.clientHeight * .85,
              behavior: "smooth"
            })} id="scroll_indicator" className="scroll-indicator"></div>
            :
            <></>
            :
            <></>
        }
      </div>
      <IonButton onClick={() => this.nextQuestionCallback()}>{(currentQuestionIndex === NUM_QUESTIONS - 1) ? 'FINISH' : 'NEXT QUESTION'}</IonButton>
    </div>
    )
  }
};

export default withApplicationState(Review);